import Layout from "../layout";
import Typography from "@mui/material/Typography";
import React from "react";
import MiniSiteShowBanner from "./miniSiteShowBanner";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MiniSiteShowOnDemand from "./miniSiteShowOnDemand";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
  },
  container: {
    display: "flex",
  },
  onDemand: {
    padding: "20px",
    background: "url(/bkgs/bckg-home-footer.jpg) no-repeat;",
    backgroundPosition: "right",
    "@media (max-width:599px)": {
      padding: 0,
    },
  },
  title: {
    fontFamily: "OpenSans-Bold",
    fontSize: "2em",
  "@media (min-width:600px) and (max-width:700px)": {
      fontSize: "2em",
    },
  "@media (min-width:475px) and (max-width:700px)": {
      fontSize: "1.5em",
    },
    "@media (min-width:700px) and (max-width:900px)": {
      fontSize: "3em",
    },
  },
  noDisplay: {
    fontSize: 50,
    display: "none",
  },
  display: {
    fontSize: 50,
    display: "flex",
    "@media (max-width:475px)": {
      fontSize: 30,
    },
  },
  bigGallery: {
    width: "100%", 
    '& ul': {
      height:"100%",
    },
  },
  showBanner: {
    backgroundColor: "#cd7f9e",
  },
  titleContainer: {
    paddingTop: "1rem",
    color: "white",
    "@media (min-width:600px)": {
      paddingTop: ".4em",
    },
  },
  synopsis: {
    background: "linear-gradient(90deg, rgba(65,1,61,1), rgba(74,46,72,1))",
    padding: "1rem",
    minHeight: "50px",
    "@media(max-width:475px)": {
      minHeight: "150px",
    },
  },
}));

const TNOBusinessMiniSite = () => {
  const classes = useStyles();

  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    }
  };

  return (
      <Layout>
        <MiniSiteShowBanner showSlug={"tnobusiness"} />
        <Grid container className={classes.root}>
          {/**end talento grid */}
          <Grid item xs={12} container alignItems="stretch">
            <Grid
              item
              xs={12}
              container
            >
            </Grid>
            {/**End schedule grid */}
            <Grid
              className={`${classes.titleContainer} ${classes.synopsis}`}
              container
              alignItems="flex-start"
              item
              xs={12}
            >
              <Typography className={classes.title} fontFamily={"OpenSans-Bold"}>
                Conversaciones con empresarios que lo han logrado.
              </Typography>
            {/*}   <MiniSiteSynopsis
                synopsis={"tnobusiness"}
  />*/}
            </Grid>
            {/**end synopsis title */}
            
          </Grid>
          {/**end host1 content */}
          <Grid item xs={12} className={classes.onDemand} container>
            {/*} TAMBIÉN PUEDES VER...*/}
            <MiniSiteShowOnDemand showSlug={"tnobusiness"} />
          </Grid>
        </Grid>
        {/*Minisite container*/}
      </Layout>
  );

};

export default TNOBusinessMiniSite;
