import { ShowRepository } from '../../../domain/ShowRepository'

export class DetailShowGetter {
    
 private repository: ShowRepository;

  constructor(repository: ShowRepository) {
    this.repository = repository;
  }

  async run(slug: string): Promise<any> {
    return await this.repository.getShow(slug);
  }
}