import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import { fetchShowAdvertisers } from "../../redux/actions/shows/fetchShowAdvertisers";
import { Buffer } from "buffer";

const useStyles = makeStyles(() => ({
  adContainer: {
    paddingRight: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    background: "rgba(11, 135, 147, 1)",
  },
  itemClass: {
    minWidth: "100px",
  },
}));

const MiniSiteShowAdsGallery = ({ showId }) => {
  const classes = useStyles();
  const [advertisers, setAdvertisers] = useState([]);

  useEffect(() => {
    getAdvertisers();
  });

  const getAdvertisers = async () => {
    const response = await fetchShowAdvertisers(showId);
    setAdvertisers(response);
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 6,
      width: 100,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
  <div>
    <Carousel
      infinite
      slidesPerPage={6}
      slidesPerScroll={1}
      animationSpeed={1500}
      autoPlay={3000}
      offset={0}
      itemWidth={100}
      stopAutoPlayOnHover
      responsive={responsive}
      itemClass={classes.itemClass}
    >
      {Array.isArray(advertisers) && advertisers.map((advertiser) => {
        var data = Buffer.from(advertiser.image.data, "binary").toString(
          "base64"
        );
        return (
          <div key={advertiser.id} className={classes.adContainer}>
            <a href={advertiser.website} target="_blank" rel="noreferrer">
              <Image
                src={`data:image/jpeg;base64,${data}`}
                alt={advertiser.name}
                fluid
              />
            </a>
          </div>
        );
      })}
    </Carousel>
    </div>
  );
};

export default MiniSiteShowAdsGallery;
