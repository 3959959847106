import Layout from "../components/layout";
import React, { useReducer } from "react";
import { fetchHosts } from "../redux/actions/users/fetchHosts";
import { fetchHostsReducer } from "../redux/reducers/users/fetchHostsReducer";
import UserCard from "../components/userCard";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

const useRowStyles = makeStyles({
  root: {
    background:
      "linear-gradient(to left, rgba(11, 135, 147, 1), rgba(48, 65, 140, 1))",
  },
  cardWrapper: {},
});

const Hosts = () => {
  const classes = useRowStyles();
  const [hostsList, dispatch] = useReducer(fetchHostsReducer, []);

  const getHosts = async () => {
    const response = await fetchHosts();
    dispatch({type: "FETCH_HOSTS", hostsList: response});
  };

  React.useEffect(() => {
    getHosts();
  }, []);

    return (
      <Layout>
        <Grid className={classes.root} container>
          {hostsList?.map((host) => {
            return (
              <Grid
                key={host._id}
                className={classes.cardWrapper}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <UserCard user={host} />
              </Grid>
            );
          })}
        </Grid>
      </Layout>
    );
};

export default Hosts;
