import { AxiosUserRepository } from "../../../core/users/infrastructure/repository/AxiosUserRepository";
import { UserRepository } from "../../../core/users/domain/UserRepository";
import { User } from "../../../core/users/domain/User";
import * as types from "../../types/types";
import { DetailUserGetterById } from "../../../core/users/application/use_cases/getUserDetailById/detailusergetterbyid";

const userRepository: UserRepository = new AxiosUserRepository();
const userByIdUserGetter = new DetailUserGetterById(userRepository);
let userById: User;

export const fetchUserById = (_id) => async (dispatch) => {
  try {
    userById = await userByIdUserGetter.run(_id);
    return dispatch({ type: types.FETCH_USER_BY_ID, userById: userById });
  } catch (err) {
    console.log("fetchUserById ", err);
    return dispatch({
      type: types.FETCH_USER_BY_ID,
      userById: null,
    });
  }
};



export const getUserById = async (_id) => {
  try {
    userById = await userByIdUserGetter.run(_id);
    return userById;
  } catch (err) {
    console.log("fetchUserById ", err);
    return null;
  }
};