import Layout from "../../layout";
import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import TalentImage from "../miniSiteHosttImage";
import MiniSiteHostBio from "../miniSiteHostBio";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
//import UserPostsList from "../miniSiteUserPosts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  container: {
    display: "flex",
    backgroundColor: "white",
  },
  paper: {
    padding: "2rem",
    "@media (max-width:800px)": {
     // margin: theme.spacing(2),
    },
  },
  title: {
    fontFamily: "OpenSans-Bold",
    fontSize: "2em",
    paddingLeft: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    width: "100%",
    background: "rgba(48, 65, 140, 1)", //linear-gradient(to bottom, )) no-repeat",
    color: "white",
    "@media (max-width:600px)": {
      fontSize: "1em",
      fontWeight: 400,
    },
  },
  progress: {
    height: "50vh",
  },
  moreButton: {
    outline: "none !important",
    marginTop: "0.5em",
    "&:hover": {
      color: "white",
    },
  },
  titleContainer: {
    // backgroundColor:"blue",
    color: "white",
    padding: "2rem",
  },
  talentImage: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const UserMiniSite = (props) => {
  const { userBySlug } = props;
  const classes = useStyles();

  if (userBySlug !== null && userBySlug !== "not Found") {
    return (
        <Layout>
          <Grid container className={classes.root}>
            <Grid
              className={classes.talentImage}
              item
              xs={12}
              sm={4}
              container
            >
              <TalentImage
                type="profile"
                talentSlug={userBySlug.slug}
              />
            </Grid>
            <Grid container item xs={12} sm={8}>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <MiniSiteHostBio
                    name={userBySlug.name + " " + userBySlug.lastName}
                    bio={userBySlug.bio}
                    roles={userBySlug.roles}
                    department={userBySlug.department}
                    id={userBySlug._id}
                  ></MiniSiteHostBio>
                </Paper>
              </Grid>
            </Grid>
            {/*<Grid item xs={12}>
              <UserPostsList userId={userBySlug._id}></UserPostsList>
              </Grid>*/}
          </Grid>
        </Layout>
    );
  } else {
    if (userBySlug === null) {
      return (
        <Layout>
          <Grid
            className={classes.progress}
            container
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        </Layout>
      );
    } else if (userBySlug === "not Found")
      return (
        <div>
          <Layout>
            <Grid
              className={classes.progress}
              container
              alignItems="center"
              justifyContent="center"
            >
              <h1>{"El talento que buscas no existe o está mal escrito."}</h1>
            </Grid>
          </Layout>
        </div>
      );
  }
};

export default UserMiniSite;
