import { OnDemandRepository } from '../../../core/shows/domain/OnDemandRepository'
import { AxiosOnDemandRepository } from '../../../core/shows/infrastructure/repository/AxiosOnDemandRepository'
import { AllShowFileNamesGetter } from '../../../core/shows/application/useCases/getShowFileNames/allshowfilenamesgetter'
import * as types from '../../types/types'

const showRepository: OnDemandRepository = new AxiosOnDemandRepository();
const showFileNamesGetter = new AllShowFileNamesGetter(showRepository);

export const fetchShowFileNames = async (showSlug: String) =>  {
    try {
      const response = await showFileNamesGetter.run(showSlug);
      console.log(response)
      return response;
   } catch (err) {
      console.log(err);
      return [];
   } 
}

export const fetchReduxShowFileNames = (showSlug: String) => (dispatch) => {
   console.log("showSlug in names", showSlug)
   showFileNamesGetter
   .run(showSlug)
   .then(data => {
       console.log(data);
       dispatch({
           type: types.FETCH_SHOW_FILE_NAMES, 
           showFileNames: data
       });        
   }).catch(err => {
      console.log(err);
      dispatch ({
         type: types.FETCH_SHOW_FILE_NAMES,
         showFileNames:[]
     });   
 }); 
}
  

 
  
