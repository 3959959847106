import Home from './pages/home';
import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import "./styles/globals.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

//custom styles
import  "./styles/styles.scss";
import "./styles/scss/app.scss";

const GID = "G-5R9WRHX2N2"; // Google Analytics ID
ReactGA.initialize(GID);

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
  }, [location]);

  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
