import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
//import LogoNegativo from '../../LogoNegativo.png'
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import RoomRoundedIcon from "@mui/icons-material/RoomRounded";
import EmailIcon from "@mui/icons-material/Email";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "url('/bkgs/bkgd-profile.jpg') no-repeat",
    backgroundImage:
      "linear-gradient(to bottom, rgba(11, 135, 147, 1), rgba(48, 65, 140, 1)), url('/bkgs/bckg-home-footer.jpg') no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "center",
    color: "white",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  box: {
    padding: "2em",
    "@media (max-width:599px)": {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  },
  typo: {
    color: "white",
    minWidth: "36px !important",
  },
  weAre: {
    paddingTop: "1em",
    color: "white !important",
  },
  contact: {
    paddingTop: "1em",
    color: "white",
  },
  address: {
    color: "white",
    paddingTop: ".5em",
  },
  listItem: {
    padding: "0 !important",
    color: "white",
  },
  listText: {
    color: "white",
  },
  copy: {
    background: "#360033",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  logo: {
    width: "20%",
    "@media (max-width:767px)": {
      width: "40%",
      //backgroundColor: 'red',
    },
  },
  contactTitle: {
    textTransform: "uppercase",
    color: "white",
    fontFamily: "OpenSans-Bold !important",
    "@media (max-width:767px)": {
      fontSize: "2rem",
    },
  },
}));

export default function FooterCopyRight() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" className={classes.contactTitle} fontFamily="Opensans-Bold">
        Contacto
      </Typography>
      <Divider
        style={{ backgroundColor: "rgba(11, 135, 147, 1)", opacity: "0.3" }}
      />
      <List component="nav">
        <br></br>
        <ListItem className={classes.listItem} button>
          <ListItemIcon className={classes.typo}>
            <PhoneInTalkRoundedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={
              <Typography
                type="caption"
                fontFamily={"OpenSans-Light !important"}
                style={{
                  color: "#FFFFFF",
                  fontSize: "1em",
                }}
              >
                +58 212 959-1808 - Principal
              </Typography>
            }
          />
        </ListItem>
      
        <ListItem className={classes.listItem} button>
          <ListItemIcon className={classes.typo}>
            <PhoneInTalkRoundedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={
              <Typography
                type="caption"
                fontFamily={"OpenSans-Light !important"}
                style={{
                  color: "#FFFFFF",
                  fontSize: "1em",
                }}
              >
                +1 786 632 82 89 - Miami
              </Typography>
            }
          />
        </ListItem>
        <ListItem className={classes.listItem} button>
          <ListItemIcon className={classes.typo}>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={
              <Typography
                type="caption"
                fontFamily={"OpenSans-Light !important"}
                style={{
                  color: "#FFFFFF",
                  fontSize: "1em",
                }}
              >
                info@tnoradio.com
              </Typography>
            }
          />
        </ListItem>
        <ListItem className={classes.listItem} button>
          <ListItemIcon className={classes.typo}>
            <RoomRoundedIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            secondary={
              <Typography
                type="caption"
                fontFamily={"OpenSans-Light !important"}
                style={{
                  color: "#FFFFFF",
                  fontSize: "1em",
                }}
              >
                <Link
                  style={{ color: "#FFFFFF" }}
                  href="https://goo.gl/maps/2v8Yhk5vsVmffoKU8"
                  underline="none"
                >
                  Centro Ciudad Comercial Tamanaco. <br /> CCCT. Chuao.
                  Caracas-Venezuela.
                </Link>
              </Typography>
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
