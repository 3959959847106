import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Show } from "../../core/shows/domain/Show";
import { IShowScheduleRow } from "../../interfaces/IShowScheduleRow";
import utils from "../../core/shows/domain/utils";
import Row from "./showRow";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
  },
  container: {
    display: "flex",
  },

  progress: {
    height: "50vh",
  },
}));

/**
 * Component, renders the shows schedule of a day.
 */
const ScheduleOfTheDay = ({ weekday, showsOnWeekdayList, loading }) => {
  const classes = useStyles();
  let dataRows: IShowScheduleRow[] = []; //Data to display in show row
  let weekDayParam = weekday;
  const [showsOfTheDay, setShowsOfTheDay] = useState(dataRows);
  weekDayParam = weekday === "miércoles" ? "miercoles" : weekday;
  weekDayParam = weekday === "sábado" ? "sabado" : weekday;

  useEffect(() => {
    dataRows = [];
    getShowsOfTheDay();
  }, [weekday, showsOnWeekdayList, loading]);

  const getShowsOfTheDay = () => {
    let shows: Show[] = showsOnWeekdayList;
    let row: IShowScheduleRow;
    let scheduleIds: Number[] = []; // aux to store ids
    shows.forEach(
      //TODO HACER LA FILA CON EL INITTIME DIRECTO
      (show) => {
        show.showSchedules.forEach((schedule) => {
          if (
            scheduleIds.includes(schedule.id) === false &&
            schedule.weekDay === weekDayParam
          ) {
            row = new IShowScheduleRow(
              show.id,
              show.name,
              show.genre,
              show.type,
              show.synopsis,
              show.pgClassification,
              show.hosts,
              schedule.startTime,
              show.showSchedules,
              show.showSlug
            );
            scheduleIds.push(schedule.id);
            dataRows.push(row);
          }
        });
      }
    );

    dataRows = utils.sortSchedule(dataRows);
    setShowsOfTheDay((showsOfTheDay) => (showsOfTheDay = dataRows));
  };


  return (
    <>
      {loading === false && 
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableBody>
              {showsOfTheDay.map((row: IShowScheduleRow) => {
                return (
                  <Row
                    key={row.id.toString() + row.startTime.toString()}
                    row={row}
                    weekday={weekDayParam}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>}
      {loading === true &&  
        <Grid
          className={classes.progress}
          container
          alignItems="center"
          justifyContent="center"
        > 
          <CircularProgress color="secondary" />
        </Grid>} 
    </>
  );
};

export default ScheduleOfTheDay;
