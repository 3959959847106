import axios from "axios";
import { OnDemandRepository } from "../../domain/OnDemandRepository";

const baseUrl = process.env.API_CDN_URL || "https://cdn.tnoradio.com/";

export class AxiosOnDemandRepository implements OnDemandRepository {
  async getShowNames(showSlug: String): Promise<any[]> {
    console.log(`${baseUrl}get_shows`)
    try {
        const response = await axios.get(`${baseUrl}get_shows/${showSlug}`);
        console.log("AxiosOnDemandRepository", response.data);
        return response.data;
      } catch (error) {
        console.log("AxiosOnDemandRepository", error);
        throw(error);
      }
  }
}
