import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

const MiniSiteSynopsis = ({synopsis}) => {
  const theme = createTheme();

  theme.typography.body1 = {
    fontSize: "1.3rem",
    fontFamily: "OpenSans-Light",
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="body1" sx={{p: "0.5rem"}}>
        {synopsis}
      </Typography>
    </ThemeProvider>
  );
};

export default MiniSiteSynopsis;
