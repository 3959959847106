import { IShowScheduleRow } from '../../../../interfaces/IShowScheduleRow'
import { Show } from '../Show';

const sortSchedule = (dataRows: IShowScheduleRow[]): IShowScheduleRow[] => {
    dataRows.sort((a,b) => {
      let keyA = a.startTime;
      let keyB = b.startTime;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return dataRows;
  }

  export default sortSchedule;

  const sortShowsSchedule = (shows: Show[]): Show[] => {
    shows.forEach(show => {
      show.showSchedules.sort((a,b) => {
        let keyA = a.startTime;
        let keyB = b.startTime;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })
    });

    return shows;
  }

  export { sortShowsSchedule };
