import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import { useTrackEvent } from "../hooks/useTrackEvent";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const SideGallery = () => {
  //const { trackEvent } = useTrackEvent();
  const classes = useStyles();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Carousel
      ssr
      itemClass="image-item"
      responsive={responsive}
      infinite
      autoPlay
      transitionDuration={4000}
      arrows={false}
    >
      {images.slice(0, 5).map((image) => {
        return (
          <div key={image.id}>
            <Link
              target="_blank"
              rel="noopener"
              href={image.href}
              underline="none"
            >
              <img className={classes.image} src={image.source} alt="TNO Radio Visual Online"></img>
            </Link>
          </div>
        );
      })}
    </Carousel>
  );
};

const images = [
  //  {source: "/sideGallery/iberico_tnoradio_playerside.jpg", id:"iberico", href:"https://www.instagram.com/ibericogourmet"},
  {
    source: "/sideGallery/protecnoplayerside.jpg",
    id: "protecno",
    href: "https://www.instagram.com/protecnonetworks",
  },
  {
    source: "/sideGallery/posadaplayerside.jpg",
    id: "posadaamadamia",
    href: "https://g.page/PosadaAmadaMia?share",
  },
  {
    source: "/sideGallery/bmjplayerside.jpg", 
    id:"bmj", 
    href:"https://instagram.com/tnoradio"
  },
  // {source: "/sideGallery/kaldicafe_tnoradio_playerside.jpg", id:"cafekaldi", href:"https://www.cafekaldivenezuela.com"}
];

export default SideGallery;
