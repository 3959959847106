import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button, Card } from "react-bootstrap";
import { useEffect, useMemo, useReducer } from "react";
import { fetchPostAuthor } from "../../redux/actions/posts/fetchPostAuthor";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { PostAuthorReducer } from "../../redux/reducers/posts/fetchPostAuthor";

const useRowStyles = makeStyles((isMiniSiteHeight) => ({
  title: {
    fontFamily: "OpenSans-Bold !important",
    color: "rgba(48, 65, 140, 1)",
    fontSize: "1.5rem",
  },
  summary: {
    fontFamily: "OpenSans-Light !important",
    color: "rgba(11, 135, 147, 1)",
  },
  label: { fontFamily: "OpenSans-Light !important", fontSize: "0.5rem", marginRight: "0.5rem", fontWeight: "bold !important"},
  card: {
    width: "100%",
    height: "100%",
    minHeight: isMiniSiteHeight === true ? "20rem" : "18rem",
    backgroundColor: "transparent",
  },
}));

const PostSummary = (props) => {
  const {
    title,
    image,
    tags,
    author,
    summary,
    slug,
    showImage,
    isMiniSiteHeight,
  } = props;

  const { trackEvent } = useTrackEvent();
  const classes = useRowStyles(isMiniSiteHeight);
  const [postAuthor, dispatch] = useReducer(PostAuthorReducer, null);
  const colors = Array("primary", "success", "danger", "info", "deep-purple", "pink", "lime");

  useEffect(() => {
    getPostAuthor(author);
  }, [author]);

  const getPostAuthor = async (author) => {
    const response = await fetchPostAuthor(author);
    dispatch({ type: "FETCH_POST_AUTHOR", postAuthor: response });
  }

  return (
    <Card className={classes.card}>
      <Card.Body>
        <Link
          underline="none"
          rel="noopener"
          href={`/contenido/${slug}`}
          onClick={() => {
            trackEvent("post_title_click", "posts", "post_title_click", "Post");
          }}
        >
          <Card.Title className={classes.title}>{title}</Card.Title>
        </Link>
        <Card.Text className={classes.summary}>{summary}</Card.Text>
        <Card.Text className={classes.summary}>
          Autor:{" "}
          <Link
            href={
              postAuthor !== null
                ? "/equipo/" + postAuthor?.slug
                : "contenido/posts"
            }
            target="_blank"
            underline="none"
          >
            {postAuthor !== null ? `${postAuthor?.name} ${postAuthor.lastName}` : author}
          </Link>
        </Card.Text>
      </Card.Body>
       <Card.Footer>
        {useMemo(() => 
          tags.map((tag, index) => (
            <Button
              key={index}
              className={classes.label}
              variant={colors[Math.floor(Math.random()*colors.length)]}
              size="sm"
            >
              {tag}
            </Button>
          ))
        , [tags])
        }
      </Card.Footer>
    </Card>
  );
};

export default PostSummary;
