//Show Action Types
export const FETCH_SHOWS = "FETCH_SHOWS";
export const FETCH_SHOW_BANNERS = "FETCH_SHOW_BANNERS";
export const FETCH_SHOW_RESPONSIVE_BANNERS = "FETCH_SHOW_RESPONSIVE_BANNERS";
export const ONLINE_SHOW = "ONLINE_SHOW";
export const SHOWS_FILTERED_BY_DAY = "SHOWS_FILTERED_BY_DAY";
export const GET_TODAY = "GET_TODAY";
export const FETCH_SHOW_BY_SLUG = "FETCH_SHOW_BY_SLUG";
export const FETCH_SHOW_IMAGE = "FETCH_SHOW_IMAGE";
export const FETCH_SHOW_MINI_BANNER = "FETCH_SHOW_MINI_BANNER";
export const FETCH_SHOW_IMAGE_URL = "FETCH_SHOW_IMAGE_URL";
export const CHANGE_LOAD_SCHEDULE_STATE = "CHANGE_LOAD_SCHEDULE_STATE";
export const FETCH_SHOW_ADVERTISERS = "FETCH_SHOW_ADVERTISERS";
export const FETCH_SHOW_FILE_NAMES = "FETCH_SHOW_FILE_NAMES";

//Util action types
export const SET_SELECTED_SCHEDULE_DAY = "SET_SELECTED_SCHEDULE_DAY";
export const SET_NAVBAR_DAY = "SET_NAVBAR_DAY";

//User Action Types
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_HOSTS = "FETCH_HOSTS";
export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_USER_BY_SLUG = "FETCH_USER_BY_SLUG";
export const FETCH_USER_IMAGE = "FETCH_USER_IMAGE";
export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";
export const FETCH_USER_SHOWS = "FETCH_USER_SHOWS";

//Post Action Types
export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_POST_BY_SLUG = "FETCH_POST_BY_SLUG";
export const FETCH_POST_BY_ID = "FETCH_POST_BY_ID";
export const FETCH_POST_IMAGE = "FETCH_POST_IMAGE";
export const FETCH_POSTS_PAGE = "FETCH_POSTS_PAGE";
export const FETCH_POST_AUTHOR = "FETCH_POST_AUTHOR";

//Chat Action Types
export const MAKE_TEXTINPUT_REQUEST = "MAKE_TEXTINPUT_REQUEST";
export const ADD_USER_TEXT_INPUT = "ADD_USER_TEXT_INPUT";
