import * as types from "../../types/types";

let initialState = [];

export const ShowsOfTheDayReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOWS_FILTERED_BY_DAY:
      return action.showsOnWeekdayList;
    default:
      return state;
  }
};
