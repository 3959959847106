import { AxiosShowRepository } from "../../../core/shows/infrastructure/repository/AxiosShowRepository";
import { DetailShowGetter } from "../../../core/shows/application/useCases/getShowDetail/detailshowgetter";
import { ShowRepository } from "../../../core/shows/domain/ShowRepository";

const showRepository: ShowRepository = new AxiosShowRepository();
const detailShowGetter: DetailShowGetter = new DetailShowGetter(showRepository);

export const fetchShowBySlug = async (slug: string) => {
  try {
    return await detailShowGetter.run(slug);
  } catch (err) {
    console.log("fetchShowBySlug ", err);
    return "not Found";
  }
};
