import { PostRepository } from "../../../domain/PostRepository";
import Post from "../../../domain/Post";

export class PostsPageGetter {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(pageSize: number, page: number): Promise<Post[]> {
    return await this.repository.getPostsPage(pageSize, page);
  }
}
