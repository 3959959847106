/**
 * Implements Show repository interface with axios
 * promise based HTTP client.
 */

import { Show } from "../../domain/Show";
import { ShowRepository } from "../../domain/ShowRepository";
import axios from "axios";
import { ShowImage } from "../../domain/ShowImage";
import { ShowAdvertiser } from "../../domain/ShowAdvertiser";

//const baseUrl = process.env.API_SHOWS_URL || "";
const baseUrl = "https://shows.tnoradio.com/api/shows/";
//const baseUrl = "http://127.0.0.1:4000/api/shows/";

export class AxiosShowRepository implements ShowRepository {
  public async getShowsOnWeekday(weekDay: string): Promise<Show[] | []> {
    try {
      const response = await axios.get(baseUrl + "weekday/" + weekDay);
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository", error);
      return error;
    }
  }

  public async getShow(showLink: String): Promise<Show> {
    try {
      const response = await axios.get(baseUrl + "details/" + showLink);
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getShowBySlugWithHosts(slug: string): Promise<any> {
    try {
      const response = await axios.get(baseUrl + "showwithhosts/slug/" + slug);
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async index(): Promise<Show[]> {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getShowBanners(imageName: String): Promise<ShowImage[]> {
    try {
      const response = await axios.get(baseUrl + "showbanners/" + imageName);
      return response.data;
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async indexEnabled(): Promise<Show[]> {
    try {
      const response = await axios.get(baseUrl + "enabled");
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getOnlineShowByTime(time: String): Promise<Show> {
    try {
      const response = await axios.get(baseUrl + "bytime/" + time);
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository", error);
      return error;
    }
  }

  public async getShowImage(
    imageName: String,
    showSlug: String
  ): Promise<ShowImage> {
    try {
      const response = await axios.get(
        `${baseUrl}image/${imageName}/${showSlug}`
      );
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository ", error);
      return error;
    }
  }

  public async getShowAdvertisers(showId: string): Promise<ShowAdvertiser[]> {
    try {
      const response = await axios.get(`${baseUrl}advertisers/${showId}`);
      return response.data;
    } catch (error) {
      console.log("AxiosShowRepository", error);
      return error;
    }
  }
}
