import { AxiosUserRepository } from "../../../core/users/infrastructure/repository/AxiosUserRepository";
import { UserRepository } from "../../../core/users/domain/UserRepository";
import * as types from "../../types/types";
import { DetailUserGetterById } from "../../../core/users/application/use_cases/getUserDetailById/detailusergetterbyid";

const userRepository: UserRepository = new AxiosUserRepository();
const userByIdUserGetter = new DetailUserGetterById(userRepository);

export const fetchPostAuthorRedux = (_id) => async (dispatch) => {
  try {
    const author = await userByIdUserGetter.run(_id);
    return dispatch({ type: types.FETCH_POST_AUTHOR, author: author });
  } catch (err) {
    console.log("fetchPostAuthor ", err);
    return dispatch({
      type: types.FETCH_POST_AUTHOR,
      author: null,
    });
  }
};

export const fetchPostAuthor = async (_id: string) => {
  try {
    const author = await userByIdUserGetter.run(_id);
    return author;
  } catch (err) {
    console.log("fetchPostAuthor ", err);
    return null;
  }
};
