import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import FooterCopyRight from "./footerCopyRight";

const useStyles = makeStyles((theme) => ({
  footer: {
    // background: "url('/bkgs/bkgd-profile.jpg') no-repeat",
    background:
      "linear-gradient(to bottom, rgba(11, 135, 147, 1), rgba(48, 65, 140, 1)), url('/bkgs/bckg-home-footer.jpg') no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "center",
    color: "white",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  box: {
    padding: "2em",
    "@media (max-width:599px)": {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  },
  typo: {
    color: "white",
    fontFamily: "OpenSans-Bold !important",
    "@media (max-width:599px)": {
      fontSize: "2rem",
    },
  },
  weAre: {
    paddingTop: "0.8em !important",
    marginTop: "0 !important",
    color: "white !important",
    fontSize: "1rem !important",
    fontFamily: "OpenSans-Light !important",
    //textAlign: "justify",
  },
  listItem: {
    padding: "0 !important",
    color: "white",
  },
  copy: {
    background: "rgba(48, 65, 140, 1)",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  logo: {
    width: "40%",
  },
  copyRight: {
    background: "rgba(11, 135, 147, 1)",
    textAlign: "center",
    padding: "1em",
    color: "#FFFFFF",
    fontSize: "1em",
    fontFamily: "OpenSans-Light !important",
  }
  ,
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item className={classes.footer} xs={12} md={9}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className={classes.box}>
                <Typography className={classes.typo} variant="h4">
                  TNO Radio
                </Typography>
                <Divider />
                <Typography
                  className={classes.weAre}
                  variant="caption"
                  paragraph
                >
                  Somos la primera radio visual de Venezuela y pioneros de la
                  radio visual desde 2014.
                </Typography>

                <Typography
                  className={classes.weAre}
                  variant="caption"
                  paragraph
                >
                  Dentro de nuestra grilla tenemos más de 30 programas de
                  producción original con temas de: Actualidad, información,
                  motivación, economía y finanzas, educación, gastronomía,
                  deportes, fitness, cine y teatro, música, tecnología, salud,
                  ecología, viajes y turismo, emprendimiento, negocios, moda,
                  mercadeo y ventas, arte, espectáculo, etiqueta, protocolo,
                  estilos de vida, bienes raíces y mucho más.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className={classes.box}>
                <Typography className={classes.typo} variant="h4">
                  Entrevistas
                </Typography>
                <Divider />
                <Typography
                  className={classes.weAre}
                  variant="caption"
                  align="justify"
                  style={{ marginTop: "1rem" }}
                >
                  produccion@tnoradio.com
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.typo}
                  style={{ paddingTop: "1rem" }}
                >
                  Prensa
                </Typography>
                <Divider />
                <Typography
                  className={classes.weAre}
                  variant="caption"
                  align="justify"
                >
                  prensa@tnoradio.com
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.typo}
                  style={{
                    paddingTop: "1rem",
                  }}
                >
                  Comercialización
                </Typography>
                <Divider />
                <Typography
                  className={classes.weAre}
                  variant="caption"
                  align="justify"
                >
                  mercadeoyventas@tnoradio.com
                </Typography>
              </Box>
            </Grid>

            {/*  <Grid item xs={12} md={3}>
              <Box className={classes.box}>
                <CommunityList></CommunityList>
              </Box>
                </Grid>*/}
            {/*}  <Grid item xs={12} sm={6} lg={4}>
                        <Box className={classes.box}>
                            <Typography className={classes.typo} variant="h6">Trending</Typography>
                            <Divider/>
                        </Box>
                    </Grid>*/}
          </Grid>{" "}
          {/*Container*/}
        </Grid>{" "}
        {/*item*/}
        <Grid item className={classes.copy} xs={12} md={3}>
          <Box className={classes.box}>
            <FooterCopyRight></FooterCopyRight>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.copyRight}>
          <Typography
            type="caption"
            fontFamily={"OpenSans-Light"}
        >
            {" © "}
            Corporación TNO, C.A. J-501370338. Todos los derechos reservados. {new Date().getFullYear()}
            {"."}
        </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
