import React from "react";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import utils from "../../core/shows/domain/utils";

const createScheduleData = (weekDay: string, startTime: string) => {
  return { weekDay, startTime };
};

const useRowStyles = makeStyles({
  time: {
    color: "white",
    paddingLeft: "1em",
    paddingRight: "1em",
    backgroundColor: "rgba(48, 65, 140, 1)",
  //  ["@media (max-width:475px)"]: {
 //     padding: ".5em",
//      lineHeight: ".8em",
//      fontSize: "0.6em",
 //   },
  },
  otherSchedulesDays: {
    paddingLeft: "0em",
    paddingRight: "0.5em",
    fontSize: "1em",
    textAlign: "right",
    textTransform: "uppercase",
  //  ["@media (max-width:767px)"]: {
  //    fontSize: "0.6em",
  //  },
  },
  otherSchedulesTime: {
    paddingLeft: "0.5em",
    paddingRight: "0em",
    fontSize: "1em",
   // ["@media (max-width:475px)"]: {
   //   fontSize: "0.6em",
  //  },
  },
  schedulesCell: {
 //   ["@media (max-width:475px)"]: {
   //   paddingLeft: "0em",
  //  },
  },
  root: {
    "& > *": {
      padding: "0px",
    },
  },
});

/**
 * Receives a  list with the row data and renders a table with
 * show rows.
 * @param props
 */
function ScheduleRow(props: { row: ReturnType<typeof createScheduleData> }) {
  const { row } = props;
  

  const classes = useRowStyles();

  return (
    <TableRow className={classes.root}>
      <TableCell >
        <Typography className={classes.otherSchedulesDays}>
          {utils.shortenDay(row?.weekDay)}
        </Typography>
      </TableCell>
      <TableCell className={classes.schedulesCell}>
        <Typography className={classes.otherSchedulesTime}>
          {row?.startTime}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default ScheduleRow;
