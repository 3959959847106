import { makeStyles } from "@mui/styles";
import { fetchShowFileNames } from "../../redux/actions/shows/fetchShowsOnDemandData";
import { showsFileNamesReducer } from "redux/reducers/shows/fetchShowFilesReducer";
import ReactPlayer from "react-player/lazy";
//import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useReducer } from "react";
import { Grid } from "@mui/material";

const useStyles = makeStyles((showSlug) => ({
  player: {
    height: showSlug === "tnobusiness" ? "320px !important" : "260px !important",
    minWidth: showSlug === "tnobusiness" ? "260px !important" : "280px !important",
    width: "100% !important",
    padding: showSlug === "tnobusiness" ? "20px !important" : "10px !important",
  },
  bigGallery: {
    width: "100%", 
    height: "260px !important",
    '& ul': {
      height:showSlug === "100%",
    },
  },
}));

const MiniSiteShowOnDemand = (props) => {
  const { showSlug } = props;
  const [showFileNames, dispatch] = useReducer(showsFileNamesReducer, []);
  const classes = useStyles(showSlug);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 560, min: 0 },
      items: 1
    }
  };

  const getShowFileNames = async () => {
    const response = await fetchShowFileNames(showSlug);
    console.log(response)
    dispatch({ type: "FETCH_SHOW_FILE_NAMES", showFileNames: response });
  }

  useEffect(() => {
    getShowFileNames();
  }, [showSlug]);

  return (<>
    {/*<Carousel
      responsive={responsive}
      className={classes.bigGallery}
    >
        {showFileNames && showFileNames?.map((showFileName) => {
          const fileName = showFileName.File_Name.replace(" ", "%20");
          console.log("the name", fileName);
          return (
            <ReactPlayer 
              className={classes.player}
              key={fileName}
              url={`https://ondemand.tnoradio.com/${showSlug}/${fileName}`} 
              controls={true}
            />
        )})}
    </Carousel>*/}
      <Grid container>
      {showFileNames && showFileNames?.map((showFileName) => {
          const fileName = showFileName.File_Name.replace(" ", "%20");
          console.log("the name", fileName);
          return (
            <Grid item xs={12} md={4}>
              <ReactPlayer 
                className={classes.player}
                key={fileName}
                url={`https://ondemand.tnoradio.com/${showSlug}/${fileName}`} 
                controls={true}
              />
            </Grid>)})}
      </Grid>
      </>
  );
};

export default (MiniSiteShowOnDemand);
