import { AxiosPostRepository } from '../../../core/posts/infrastructure/repository/AxiosPostRepository'
import { DetailPostGetter } from '../../../core/posts/application/use_cases/getPost/getPostBySlug'
import { PostRepository } from '../../../core/posts/domain/PostRepository'
import * as types from '../../types/types'

const postRepository: PostRepository = new AxiosPostRepository();
const detailPostGetter: DetailPostGetter = new DetailPostGetter(postRepository);

export const fetchPostBySlugRedux = (slug) => (dispatch) => {
   detailPostGetter
     .run(slug)
     .then((data) => {dispatch({ type: types.FETCH_POST_BY_SLUG, postBySlug: data })})
     .catch((err) => {
       console.log("fetchPostErr", err);
       dispatch({
         type: types.FETCH_POST_BY_SLUG,
         postBySlug: [],
       });
     });
 };

 export const fetchPostBySlug = async (slug: string) => {
  try {
     const data = await detailPostGetter.run(slug);
     return data;
   } catch (err) {
     console.log("fetchPostErr", err);
     return null;
   }
};