import { AxiosUserRepository } from '../../../core/users/infrastructure/repository/AxiosUserRepository';
import { DetailUserGetter } from '../../../core/users/application/use_cases/getUserDetail/detailusergetter';
import { UserRepository } from '../../../core/users/domain/UserRepository';

const userRepository: UserRepository = new AxiosUserRepository();
const detailUserGetter: DetailUserGetter = new DetailUserGetter(userRepository);

export const fetchUserBySlug = async (slug: string) => {

   try {
      return await detailUserGetter.run(slug);
   }
 catch(err) {
      console.log(err);
      return null;   
   };
}