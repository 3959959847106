import { UserRepository } from '../../../domain/UserRepository'
import { UserImage } from '../../../domain/UserImage'

export class UserImageFromDBGetter {
    
 private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(name: String, slug: String): Promise<UserImage> {
    return await this.repository.getUserImage(name, slug);
  }
}