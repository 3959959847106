import { UserRepository } from "../../../core/users/domain/UserRepository";
import { AxiosUserRepository } from "../../../core/users/infrastructure/repository/AxiosUserRepository";
import { HostsGetter } from "../../../core/users/application/use_cases/hostsGetter/getHosts";

const userRepository: UserRepository = new AxiosUserRepository();
const hostsGetter = new HostsGetter(userRepository);

export const fetchHosts = async () => {
  try {
    const data = await hostsGetter.run();
    return data;
  } catch (err) {
    console.log("fetchHosts", err);
    return [];
  }
};
