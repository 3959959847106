import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import InstagramIcon from '@mui/icons-material/Instagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faTiktok, faXTwitter, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@emotion/react";

const MiniSiteSocial = ({twitter, facebook, youtube, instagram, linkedin, tunein, tikTok}) => {
  const theme = createTheme();
  const classes = {
  facebook: {
    backgroundColor: "#3B5998",
    textAlign: "center",
    color: "white",
    padding: "0.5em",
  },
  youtube: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    padding: "0.5em",
  },
  tuneIn: {
    backgroundColor: "#2cb6a7",
    color: "white",
  },
  tikTok: {
    backgroundColor: "black",
    color: "white",
    textAlign: "center",
    padding: "0.5em",
  },
  x: {
    backgroundColor: "black",
    color: "white",
    textAlign: "center",
    padding: "0.5em",
  },
  tuneInLogo: {
    height: "1.2em",
  },
  linkedin: {
    backgroundColor: "#0E76A8",
    color: "white",
    textAlign: "center",
    padding: "0.5em",
  },
  instagram: {
   padding: "0.5em",
   color: "white",
   textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(254,218,117,1), rgba(250,126,30,1), rgba(214,41,118,1), rgba(150,47,191,1), rgba(79,91,213,1))",
  },
  };
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('lg'));
  const dynamicStyles = {
    ...matchesLG && {fontSize: '1rem'},
    ...matchesXL && {fontSize: '3rem'}
  };

  const dynamicSocialStyles = {
    ...matchesLG && {height: '2.5rem'},
    ...matchesXL && {height: '4.5rem'}
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={4} sm={2}>
        <Link target="_blank" rel="noopener" href={instagram} underline="none">
          <Box
            alignItems="center"
            justifyItems={"center"}
            justifyContent={"center"}
            style={classes.instagram}
          >
            <InstagramIcon style={dynamicStyles} />
          </Box>
        </Link>
      </Grid>
      <Grid xs={4} sm={2}>
        <Link target="_blank" rel="noopener" href={twitter} underline="none">
          <Box
            alignItems="center"
            justifyItems={"center"}
            justifyContent={"center"}
            style={classes.x}
          >
            <FontAwesomeIcon icon={faXTwitter} style={dynamicStyles}/>
          </Box>
        </Link>
      </Grid>
      <Grid xs={4} sm={2}>
        <Link target="_blank" rel="noopener" href={facebook} underline="none">
          <Box
            alignItems="center"
            justifyItems={"center"}
            justifyContent={"center"}
            style={classes.facebook}
          >
            <FontAwesomeIcon icon={faFacebook} style={dynamicStyles}/>
          </Box>
        </Link>
      </Grid>
      <Grid xs={4} sm={2}>
        <Link target="_blank" rel="noopener" href={youtube} underline="none">
          <Box
            style={classes.youtube}
            alignItems="center"
            justifyItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon icon={faYoutubeSquare} style={dynamicStyles}/>
          </Box>
        </Link>
      </Grid>
      <Grid xs={4} sm={2}>
        <Link target="_blank" rel="noopener" href={tikTok} underline="none">
          <Box
            style={classes.tikTok}
            alignItems="center"
            justifyItems={"center"}
            justifyContent={"center"}
          >
             <FontAwesomeIcon icon={faTiktok} style={dynamicStyles}/>
          </Box>
        </Link>
      </Grid>
      <Grid xs={4} sm={2}>
        <Link target="_blank" rel="noopener" href={linkedin} underline="none">
          <Box
            style={classes.linkedin}
            alignItems="center"
            justifyItems={"center"}
            justifyContent={"center"}
          >
            <FontAwesomeIcon icon={faLinkedinIn} style={dynamicStyles}/>
          </Box>
        </Link>
      </Grid>
    </ThemeProvider>
  );
};

export default MiniSiteSocial;
