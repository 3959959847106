import * as types from "../../types/types";

let initialState = [];

export const PostsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_POSTS_PAGE:
      return action.postsPage;
    default:
      return state;
  }
};
