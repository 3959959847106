import Image from "react-bootstrap/Image";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { fetchShowImage } from "../../redux/actions/shows/fetchShowImage";
import  useCheckMobileScreen  from "../../hooks/useCheckMobileScreen";
import { Buffer } from "buffer";

const useStyles = makeStyles((theme) => ({
  showBanner: {
    "@media (min-width:600px) &&  (max-width:700px) ": {},
    "@media (min-width:1300px)": {},
    image: {
      width: "100%",
      "@media (min-width:600px) &&  (max-width:700px)": {
        height: "100%",
        width: "auto",
      },
    },
  },
}));

const MiniSiteShowBanner = (props) => {
  const classes = useStyles();
  const { showSlug } = props;
  const [image, setImage] = useState("");
  const [responsiveImage, setResponsiveImage] = useState("");
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    getImage();
  }, [showSlug]);

  const getImage = async () => {
    const image = await fetchShowImage("showMiniSiteBanner", showSlug);
    const responsiveImage = await fetchShowImage(
      "responsiveMiniSiteBanner",
      showSlug
    );
    setImage(image);
    setResponsiveImage(responsiveImage);
  }

  const getBannerUrl = () => {
    var minisiteBanner = isMobile ? responsiveImage : image;
    var data = minisiteBanner?.file?.data
      ? Buffer.from(minisiteBanner.file?.data, "binary").toString("base64")
      : undefined;

    return data ? `data:image/jpeg;base64,${data}` : minisiteBanner?.url;
  };

  return (
    <div className={classes.showBanner}>
      <Image 
        style={{ width: "100%" }} 
        src={showSlug === 'tnobusiness' ? "miniSiteBanner_tnoradio_tnobussiness.webp" : getBannerUrl()} fluid>
      </Image>
    </div>
  );
};

export default MiniSiteShowBanner;
