import { UserRepository } from "../../../core/users/domain/UserRepository";
import { AxiosUserRepository } from "../../../core/users/infrastructure/repository/AxiosUserRepository";
import { UserImageFromDBGetter } from "../../../core/users/application/use_cases/userimagefromdbgetter/getUserImageFromDB";

const userRepository: UserRepository = new AxiosUserRepository();
const userImageFromDBGetter = new UserImageFromDBGetter(userRepository);

const fetchUserImage = async (name: String, slug: String) => {
  try {
    return await userImageFromDBGetter.run(name, slug);
  } catch (err) {
    console.log("fetchUserImage ", err);
    return "not Found";
  }
};

export default fetchUserImage;
