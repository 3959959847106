import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getPostsPage } from "../redux/actions/posts/fetchPostsPage";
import { Card, CardActions, CardContent, CardHeader, Typography, Link } from "@mui/material";
import { Favorite } from "@mui/icons-material";
import { useTrackEvent } from "../hooks/useTrackEvent";
import PostTags from "./posts/tagsComponent";

const PostsSummaryCarousel = () => {
  const { trackEvent } = useTrackEvent();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0); 
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const fetchPosts = async (pageSize) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getPostsPage(pageSize, page);
      const newPosts = response.filter((post) => !posts.includes(post));
      setPosts([...posts, ...newPosts]);
      setPage(page + 1);
    }
    catch (error) {
      setError(error);
    } 
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(12);
  }, []);

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button onClick={() => onClick()} />;
  };

  return (
    <div>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        afterChange={(previousSlide, { currentSlide, onMove }) => {
          fetchPosts(12);
        }}
        beforeChange={(nextSlide, { currentSlide, onMove }) => {
          fetchPosts(12);
        }}
      >
        {posts?.map((post) => {
          return (
            <>
              {post.approved && (
                <Card key={post._id} sx={{height: 300, paddingTop: 2}}>
                   <CardActions sx={{height: 10, justifyContent: "start"}}>
                    <PostTags tags={post.tags} tagSize="0.7rem" isHome/>
                  </CardActions>
                  <CardHeader
                    title={post.title}
                    subheader={post.author}
                    titleTypographyProps={{
                      variant: "h2", 
                      fontFamily: "OpenSans-Bold", 
                      color: "primary", 
                      fontSize: "1rem",
                    }}
                    sx={{height: 80, paddingTop: 5}} 
                  />
                  <CardContent sx={{height:150}}>
                    <Typography variant="body2" fontFamily={"OpenSans-Light"} >{post.summary}</Typography>
                  </CardContent>
                  <CardActions sx={{height: 30, justifyContent: "end"}}>
                    <Link
                      key={`${post._id}_link`}
                      underline="none"
                      rel="noopener"
                      href={`/contenido/${post.slug}`}
                      onClick={() => {
                        trackEvent("home", "click", "post_more_click");
                      }}>
                      Leer más
                    </Link>
                  </CardActions>
                </Card>
              )}
            </>
          );
        })}
      </Carousel>
    </div>
  );
};

export default PostsSummaryCarousel;
