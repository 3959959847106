/**
 * Implements Post repository interface with axios
 * promise based HTTP client.
 */

import Post from "../../domain/Post";
import { PostRepository } from "../../domain/PostRepository";
//import useSWR from 'swr'
import axios from "axios";
import { Tag } from "reactstrap";
import PostImage from "../../domain/PostImage";

const baseURL = "https://posts.tnoradio.com/api/posts/";

export class AxiosPostRepository implements PostRepository {
  saveTag(tag: Tag): Promise<Tag | Error> {
    throw new Error("Method not implemented.");
  }
  async getAll(): Promise<Post[]> {
    try {
      const response = await axios.get(baseURL + "index");
      return response.data;
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  async getPostsPageByOwner(
    pageSize: number,
    page: number,
    owner: String
  ): Promise<Post[]> {
    try {
      const response = await axios.get(
        baseURL + "index/" + pageSize + "/" + page + "/" + owner
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
  
  async getPostsPage(pageSize: number, page: number): Promise<Post[]> {
    try {
      const response = await axios.get(
        baseURL + "index/" + pageSize + "/" + page
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
  update(post: Post, id?: String): Promise<Post | Error> {
    throw new Error("Method not implemented.");
  }
  destroy(postId: String): Promise<any> {
    throw new Error("Method not implemented.");
  }
  delete(postId: String): Promise<Post | Error> {
    throw new Error("Method not implemented.");
  }
  savePostImage(image: any): Promise<PostImage> {
    throw new Error("Method not implemented.");
  }
  updatePostImage(image: any, slug: any, name: any): Promise<PostImage> {
    throw new Error("Method not implemented.");
  }
  public async save(post: Post): Promise<Post> {
    try {
      const response = await axios.post(baseURL + "save", post);
      /**TODO: hacer un Post.create con la respuesta para agregarle el id */
      //   Post.create();
      return response.data.post_data;
    } catch (error) {
      console.log("AxiosPostRepository", error);
      return post;
    }
  }

  public async getPostBySlug(slug: string): Promise<Post> {
    try {
      const response = await axios.get(`${baseURL}details/${slug}`);
      return response.data;
    } catch (error) {
      console.log("AxiosPostRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getPostById(slug: string): Promise<Post> {
    try {
      const response = await axios.get(baseURL + "details/" + slug);
      return response.data;
    } catch (error) {
      console.log("AxiosPostRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getAllTags(): Promise<Error | Tag[]> {
    try {
      const response = await axios.get(baseURL + "index");
      const hosts = response.data.filter((post) =>
        post.roles.find((role) => role.role === "HOST")
      );
      return hosts;
    } catch (error) {
      console.log("AxiosPostRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getPostImage(name, slug) {
    let res;
    try {
      res = await axios.get(baseURL + "imagefromdb/" + name + "/" + slug);
      return res.data;
    } catch (error) {
      console.log("AxiosPostRepository", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
}
