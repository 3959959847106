import { PostRepository } from "../../../core/posts/domain/PostRepository";
import { AxiosPostRepository } from "../../../core/posts/infrastructure/repository/AxiosPostRepository";
import * as types from "../../types/types";
import { PostsPageGetter } from "../../../core/posts/application/use_cases/getPostsPage/postsPageGetter";
import { PostsPageByOwnerGetter } from "../../../core/posts/application/use_cases/getPostsPageByOwner/postsPageByOwnerGetter";

const postRepository: PostRepository = new AxiosPostRepository();
const indexPostsPage = new PostsPageGetter(postRepository);
const indexPostsPageByOwner = new PostsPageByOwnerGetter(postRepository);

export const fetchPostsPage =
  (pageSize: number, page: number) => (dispatch) => {
    indexPostsPage
      .run(pageSize, page)
      .then((data) => {
        dispatch({
          type: types.FETCH_POSTS_PAGE,
          postsPage: data,
          page: page,
          pageSize: pageSize,
        });
      })
      .catch((err) => {
        console.log("fetchPostsPage ", err);
        dispatch({
          type: types.FETCH_POSTS_PAGE,
          postsPage: [],
          page: 0,
          pageSize: 0,
        });
      });
  };

export const getPostsPageByOwner = (
  pageSize: number,
  page: number,
  owner: string
) => {
  return indexPostsPageByOwner
    .run(pageSize, page, owner)
    .then((data) => data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const getPostsPage = async (pageSize: number, page: number) => {
  try {
    const data = await indexPostsPage.run(pageSize, page);
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
