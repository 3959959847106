import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { fetchUserShowsReducer } from "redux/reducers/users/fetchUserShowsReducer";
import { useEffect, useReducer } from "react";
import { fetchUserShows } from "redux/actions/users/fetchUserShows";

const MiniSiteHostBio = ({name, bio, roles, department, id}) => {
  const theme = createTheme();
  const [userShows, dispatch] = useReducer(fetchUserShowsReducer, []);

  useEffect(() => {
    id && getUserShows(id);
  }, [id]);

  const getUserShows = async (id) => {
    const response = await fetchUserShows(id);
    dispatch({ type: "FETCH_USER_SHOWS", userShows: response });
  };


  theme.typography.h3 = {
    fontSize: "1.2rem",
    fontFamily: "OpenSans-Bold",

    "@media (max-width:599px)": {
      fontSize: "2rem",
    },
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
    },
    "@media (min-width:900px)": {
      fontSize: "2.4rem",
    },
  };

  theme.typography.h4 = {
    fontFamily: "OpenSans-Light",
    fontSize: "1rem",

    "@media (max-width:599px)": {
      fontSize: "1.5rem",
    },
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
    },
  };

  theme.typography.body1 = {
    fontSize: "2rem",
    fontFamily: "OpenSans-Light",
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
  };

  const SubTitle = () => {
    let host,
      producer,
      employee = false;

    if (roles !== undefined) {
      roles.forEach((role) => {
        if (role.role === "HOST") {
          host = true;
        } else {
          if (role.role === "PRODUCER") {
            producer = true;
          } else {
            if (role.role === "EMPLOYEE") {
              employee = true;
            }
          }
        }
      });
    }

    if (host === true)
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="h4">{"Talento TNO Radio"}</Typography>
          <Typography variant="h5">Host en {
            userShows.map((show) => {
              return (
                <Link key={show.showSlug} to={`/${show.showSlug}`}>{show.name}</Link>
              );
            })
          }</Typography>
        </ThemeProvider>
      );
    else if (employee === true)
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="h4">{department}</Typography>
        </ThemeProvider>
      );
    else if (producer === true)
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="h4">{"Productor en TNO Radio"}</Typography>
        </ThemeProvider>
      );
    else if (producer === true && host === true)
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="h4">{"Talento en TNO Radio"}</Typography>
        </ThemeProvider>
      );
    else
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="h4">{"TNO Radio"}</Typography>
        </ThemeProvider>
      );
  };

  return (
      <ThemeProvider theme={theme}>
        <Typography variant="h3" color={"rgba(48, 65, 140, 1)"} fontFamily={"OpenSans-Bold"}>
          {name}
        </Typography>
        <SubTitle />
        <Typography variant="body1" sx={{p: "0.5rem"}}>
          {bio}
        </Typography>
      </ThemeProvider>
  );
};

export default MiniSiteHostBio;
