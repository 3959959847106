import { Grid, Typography, Button } from "@mui/material";
import PostSummary from "../components/posts/postSummary";
import Layout from "../components/layout";
import React, { useState, useEffect, Fragment } from "react";
import { getPostsPage } from "../redux/actions/posts/fetchPostsPage";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(() => ({
  progress: {
    height: "40vh",
    padding: "1rem",
  },
  footer: {
    background: "#360033",
    paddingTop: "1em",
    paddingBottom: "1em",
    marginTop: "1rem",
  },
  button: {
    color: "white !important",
    borderColor: "white !important",
  },
}));

const PostsList = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0); //page number
  const classes = useStyles();

  const fetchPosts = async (pageSize) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getPostsPage(pageSize, page);
      const newPosts = response.filter((post) => !posts.includes(post));
      setPosts([...posts, ...newPosts]);
      setPage(page + 1);
    }
    catch (error) {
      setError(error);
    } 
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(24);
  }, []);


  return (
    <Layout>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="felx-start"
      >
        {posts?.map((post) => {
          return (
          <Fragment key={post._id}>
              {post.approved && (
                <Grid item xs={12} sm={6} lg={3}>
                  <PostSummary
                    title={post.title}
                    subTitle={post.subTitle}
                    image={post.image}
                    tags={post.tags}
                    author={post.owner_id}
                    slug={post.slug}
                    summary={post.summary}
                    text={post.text}
                    showImage={true}
                  />
                </Grid>
              )}
          </Fragment>
          );
        })}
      </Grid>
      {isLoading && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.progress}
        >
            <CircularProgress color="secondary" />
        </Grid>
      )}
      <Grid 
        container
        alignItems="center"
        justifyContent="center"
        className={classes.footer}>
        <Button 
          className={classes.button}
          variant="outlined"
          endIcon={<AddIcon />}
          onClick={() => fetchPosts(12)}>
            Cargar más
        </Button>
      </Grid>
      {error && <Typography>{error.message}</Typography>}
    </Layout>
  );
};

export default PostsList;
