import ScheduleOfTheDay from "../components/schedule/scheduleOfTheDay";
import Layout from "../components/layout";
import ScheduleLayout from "../components/schedule/scheduleLayout";
import React, { useEffect, useReducer } from "react";
import { fetchShowsOnDay } from "../redux/actions/shows/fetchShowsOnDay";
import { ShowsOfTheDayReducer } from "../redux/reducers/shows/showsOfTheDayReducer";
import { useParams } from "react-router-dom";

const ShowsSchedule = () => {
  const [showsOnWeekdayList, dispatch] = useReducer(ShowsOfTheDayReducer, []);
  const { weekDay } = useParams<{ weekDay: string }>();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getShowsOnWeekdayList(weekDay);
  }, [weekDay]);

  const getShowsOnWeekdayList = async (weekDay: string) => {
    setLoading(true);
    const response = await fetchShowsOnDay(weekDay);
    dispatch({ type: "SHOWS_FILTERED_BY_DAY", showsOnWeekdayList: response });
    setLoading(false);
  }

  return (
    <Layout>
      <ScheduleLayout>
        <ScheduleOfTheDay
          showsOnWeekdayList={showsOnWeekdayList}
          weekday={weekDay}
          loading={loading}
        />
      </ScheduleLayout>
    </Layout>
  );
};

export default ShowsSchedule;
