import { useCallback } from "react";
import ReactGA from 'react-ga4';

export function useTrackEvent() {
  
  const trackEvent = useCallback(
    (category: string, action: string, label: string) => {
      ReactGA.event({
        category: category,
        action: action,
        label: label, 
      });

      console.log({
        category: category,
        action: action,
        label: label,
      });
    },
    []
  );

  return { trackEvent };
}
