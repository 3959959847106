import * as types from "../../types/types";

let initialState = null;

export const fetchUserImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_IMAGE:
      return action.userImage;

    default:
      return state;
  }
};
