import Layout from "../components/layout";
//import Banners from"../components/banners";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import SideGallery from "../components/sideGallery";
//import Social from "../components/socialmedia";
import LiveShowContainer from "../containers/liveShowContainer";
//import { connect } from "react-redux";
//import { showOnline } from "../redux/actions/shows/fetchOnlineShowByTime";
//import { getToday } from "../redux/actions/utils/getToday";
import React, { useEffect, useState } from "react";
//import { bindActionCreators } from "redux";
//import ChatBot from "../components/chat/chat";
//import FloatingChatBubble from "../components/chat/floatingButton";
import Player from "../components/rplayer";
import PostsSummaryCarousel from "components/postsSummaryCarousel";
 
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "rgba(48, 65, 140, 1)",
    width: "100%",
    lineHeight: "0 !important",
    "@media (max-width:900px)": {
      //minHeight: "492px",
    },
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
  }, [props]);

 // const toggleShowChat = () => {
   // setShowChat((showChat) => !showChat);
  //};

  return (
    <div>
      {showChat && (
        <div
          style={{
            position: "fixed",
            right: "20px",
            zIndex: "1000",
            bottom: "100px",
          }}
        >
         {/* <ChatBot></ChatBot>*/}
        </div>
      )}
      {/*<div
        style={{
          position: "fixed",
          right: "20px",
          zIndex: "1000",
          bottom: "10px",
        }}
      >
        <FloatingChatBubble onClick={toggleShowChat} />
      </div>*/}
      <Layout>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={8}>
            <Player/>
          </Grid>
          <Grid item xs={12} md={4}>
            {/*<AudioComponent></AudioComponent>*/}
            <SideGallery></SideGallery>
            <LiveShowContainer></LiveShowContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <PostsSummaryCarousel />
          </Grid>
        </Grid>
       {/* <Grid container>
          <Grid item xs={12}>
            <Banners className={classes.banner}></Banners>
          </Grid>
    </Grid>*/}
        <Grid container>
          <Grid item xs={12}>
            {/*<Social></Social>*/}
          </Grid>
        </Grid>
      </Layout>
    </div>
  );
};

export default Home;
