import Footer from "../components/footer";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from "reactstrap";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    fontFamily: "OpenSans-Light",
    fontSize: "1.2rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  brand: {
  //  ["@media (max-width:599px)"]: {
  //    width: "20%",
  //  },
  },
  logo: {
    width: "13rem",
    //paddingLeft:"1rem",
   // ["@media (max-width:599px)"]: {
    //  marginLeft: "0",
   //   width: "8rem",
   // },
  },
  nav: {
    padding: "0",
    justifyContent: "right",
    color: "white",
  },
  navbar: {
    zIndex: "900",
    paddingLeft: "1rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
    paddingRight: "2rem",
    background:
      "linear-gradient(to left, rgba(11, 135, 147, 1), rgba(48, 65, 140, 1))",
  //  ["@media (max-width:599px)"]: {
    //  paddingTop: "0rem",
   // },
  },
  navlink: {
    color: "white",
    fontFamily: "OpenSans-Light",
    paddingLeft: "1rem",
    fontSize: "1.2rem",
    "&:hover": {
      fontSize: "1.3rem",
      cursor: "pointer",
      color: "white",
    },
  },
  pages: {},
}));

/**
 * This component will be shared across all pages.
 */

export default function Layout({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const classes = useStyles();
  //const router = useRouter();
  //const state = useSelector((state) => state);

  return (
    <>
      <Navbar color="dark" dark expand="md" className={classes.navbar}>
        <NavbarBrand className={classes.brand} href="/">
          <img className={classes.logo} src="/Logo_TNO_2021_white.png" alt="TNO Radio" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link
                className={classes.navlink}
                style={{
                  color: "red",
                  fontFamily: "OpenSans-Bold",
                }}
                  to="/">
                EN VIVO
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className={classes.navlink}
                to="/programacion/">
                PROGRAMACIÓN
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className={classes.navlink}
                to= "/talentos/">
                TALENTOS
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className={classes.navlink}
                to= "/contenido/posts"
              >
                CONTENIDO
              </Link>
            </NavItem>
          {/*  <NavItem>
              <NavLink
                className={classes.link}
                target="_blank"
                onClick={() => {
                  router.push(
                    {
                      pathname: "https://sistema.tnoradio.com",
                    },
                    undefined,
                    { shallow: true }
                  );
                }}
              >
                SOLICITUDES
              </NavLink>
            </NavItem>*/}
          </Nav>
        </Collapse>
      </Navbar>
      <div className={classes.pages}>{children}</div>
      <Footer></Footer>
    </>
  );
}

Navbar.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  fixed: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  // pass in custom element to use
};
