import * as types from "../../types/types";

let initialState = [];
export const fetchUserShowsReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_USER_SHOWS:
      return action.userShows;

    default:
      return state;
  }
};
