/**
 * 
 * @param time Returns a pretty format of time.
 */
const getTimeFromDate = (time: Date) => {
    let pieces = time.toString().split(':');
    let hour;
    let minute; 
    let second;
  
    if(pieces.length === 3) {
        hour = parseInt(pieces[0], 10);
        minute = parseInt(pieces[1], 10);
        second = parseInt(pieces[2], 10);
    }

    return pieces[0] + ":" + pieces[1];
  }

export default getTimeFromDate;
  