const shortenDay = (day:string): string => {
    switch (day) {      
      case 'lunes':         
        return 'lun'   
      case 'martes':
         return 'mar'      
      case 'miércoles':
         return 'mier'
      case 'miercoles':
          return 'mier' 
      case 'jueves':
         return 'jue'     
      case 'viernes':
          return 'vier'    
      case 'sábado':
            return 'sáb'
      case 'sabado':
         return 'sáb'
      case 'domingo':
        return 'dom'  
     default:
         return ''
   }
}

export default shortenDay;