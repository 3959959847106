import { ShowRepository } from '../../../domain/ShowRepository'

export class ShowImageGetter {
    
 private repository: ShowRepository;

  constructor(repository: ShowRepository) {
    this.repository = repository;
  }

  async run(imageName, showSlug): Promise<any> {
    return await this.repository.getShowImage(imageName, showSlug);
  }
}