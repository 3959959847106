import React, {forwardRef, useImperativeHandle} from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    canvas: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 4,
    pointerEvents: "none",
    },
}));

interface EmojiProps {
    height: number;
    width: number;
    setEmojiDisabled: (disabled: boolean) => void;
  }

const Emoji = forwardRef((props: EmojiProps, ref) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const classes = useStyles();
    const { width, height, setEmojiDisabled } = props;

    // you can play around with emojis + total count
    //const emoji: string[] = ['🐣', '🏝', '💛', '👑', '🤣', '🦑', '🌊', '🎀'];

    const emoji = ['❤️️', '❤️️', '❤️️']; // theme for #LetLeniLead2022
    const totalEmojiCount: number = 250;
    let continueDraw: boolean = false;
    let context: CanvasRenderingContext2D | null;
    let canvas: HTMLCanvasElement | null;
    let canvasWidth: number | undefined;
    let canvasHeight: number | undefined;
    let emojies: { emoji: string; x: number; y: number; count: number; fontSize: number; }[] = [];

    function initializeCanvas() {
        canvas = canvasRef.current;
        context = canvas.getContext("2d");
        canvasRef.current.width = window.innerWidth;;
        canvasRef.current.height = window.innerHeight;
        context.scale(2, 2);   
        generateCanvasSize(canvas);
    }

    function generateCanvasSize(canvas) {
        try {
            const coord = canvas.getBoundingClientRect();
            canvasWidth = coord.width;
            canvasHeight = coord.height;
        } catch(e){ console.log(e) } 
    }

    function drawConfetti() {
        context.clearRect(0, 0, canvasWidth, canvasHeight);
      
        emojies.forEach((emoji) => {
          drawEmoji(emoji);
          emoji.y = emoji.y - emoji.count;
        });
      
        if (continueDraw === true) {
          requestAnimationFrame(drawConfetti);
        }
    }

    function drawEmoji(emoji) {
        context.beginPath();
        context.font = emoji.fontSize + 'px serif';
        context.fillText(emoji.emoji, emoji.x, emoji.y);
    }
      
    function endDraw() {
        setTimeout(() => {
          continueDraw = false;
          setEmojiDisabled(false);
          context.clearRect(0, 0, canvasWidth, canvasHeight);
        }, 10000);
    }

    useImperativeHandle(ref, () => ({
        generateEmojis() {
            if (continueDraw === true) return;
            emojies = [];
            setEmojiDisabled(true);
            
            for (let iterate = 0; iterate < totalEmojiCount; iterate++) {
              const x = Math.floor(Math.random() * canvasWidth);
              const offsetY = Math.abs(Math.floor(Math.random() * 300));
              const fontSize = Math.floor(Math.random() * 40) + 20;
          
              emojies.push({
                emoji: emoji[Math.floor(Math.random() * emoji.length)],
                x,
                y: canvasHeight + offsetY,
                count: Math.floor(Math.random() * 3) + 4,
                fontSize,
              });
          
              if (iterate === (totalEmojiCount - 1)) {
                continueDraw = true;
                drawConfetti();
                endDraw();
              }
            }
          }
    
    }));

    React.useEffect(() => {
        initializeCanvas();
    });
    
    return (
        <canvas className={classes.canvas} ref={canvasRef}></canvas>
    )});

    export default Emoji;




