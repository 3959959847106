import { useTrackEvent } from "../hooks/useTrackEvent";
import { useCheckNetworkSpeed }  from "../hooks/useCheckNetworkSpeed";
import '@vime/core/themes/default.css';
import React, { useRef, useState, useLayoutEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Emoji from "./emoji";
import FloatingComponent from "react-floating-component";

const useStyles = makeStyles((isContent) => ({
  playerContainer: {
    position: "relative",
    paddingBottom: "56.25%", /* 16:9 */
    width: isContent ? "100%" : "unset",
    background: "rgba(48, 65, 140, 1)",
    minHeight: isContent ? "20rem" : "220px",
  },
  box: {
    width: "100%",
    height: "100%",            
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0.8,  /* for demo purpose  */
  },
  stackTop: {
    zIndex: 9,
    backgroundColor: "transparent",
    width: "10%",
    height: "10%",
    marginBottom: "55px",
    "@media (max-width: 599px)": {
      marginBottom: "75px",
      marginRight: "10px",
      width: "20%",
    }
  },
  button: {
    fontSize: "2rem",
    "&:hover": {
      fontSize: "2.5rem",
    },
    "@media (max-width: 599px)": {
      fontSize: "1.5rem",
    }
  },
  count: {
    background: "black",
    opacity: 0.7,
    color: "white",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

}));

const Player = ({isContent=false}) => {
  const playerRef = useRef(null);
  const childRef = useRef();
  const classes = useStyles(isContent);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [count, setCount] = useState(0);
  const [emojiDisabled, setEmojiDisabled] = useState(false);
  const { trackEvent } = useTrackEvent();
  const networkSpeed = useCheckNetworkSpeed();
  const url = networkSpeed <= 5 ? "https://playerv.srvstm.com/video/tnovideo2//true/true/YzNSdGRqRXVjM0oyYzNSdExtTnZiUT09K0Q=/16:9/aHR0cDovL3Rub3JhZGlvLmNvbS9Mb2dvX1ROT18yMDIxX3doaXRlLnBuZysw/nao/nao" :
  "https://playerv.srvstm.com/video/tnovideo1//true/true/YzNSdGRqRXVjM0oyYzNSdExtTnZiUT09K0Q=/16:9/aHR0cDovL3Rub3JhZGlvLmNvbS9Mb2dvX1ROT18yMDIxX3doaXRlLnBuZysw/nao/nao";

  useLayoutEffect(() => {
    setHeight(playerRef.current.clientHeight);
    setWidth(playerRef.current.clientWidth);
  });

  return (
    <div 
      className={classes.playerContainer}
      ref={playerRef}
      >
      <div className={classes.box}>
        <iframe 
          width="100%"
          height="100%" 
          src={url} 
          title="TNO Radio Player"
          onClick={() => trackEvent("player", "click", "player_click")}
          onError={(e) => {
            trackEvent("player", "error", "player_error");
            console.log(e);
          }}
          allowFullScreen>
        </iframe>
      </div>
      <div className={`${classes.box} ${classes.stackTop}`}>
        <FloatingComponent className={classes.button}>
          <Button size="large" onClick={() =>  {
            trackEvent("player", "click", "heart_click");
            setCount(count => count + 1);
            if(emojiDisabled === false) childRef.current.generateEmojis();
            }}>
            <span className={classes.button}>
              ❤️️
            </span>
          </Button>
            <br/>
            {count > 0 && <span className={classes.count}>{count}</span>}
        </FloatingComponent>
        <Emoji width={width} height={height} setEmojiDisabled={setEmojiDisabled} ref={childRef}/>
      </div>
    </div>
  )
}


export default Player;
