import { ShowRepository } from '../../../domain/ShowRepository'
import { Show } from '../../../domain/Show'

export class AllShowsGetter {
    
 private repository: ShowRepository;

  constructor(repository: ShowRepository) {
    this.repository = repository;
  }

  async run(): Promise<Show[]> {
    return await this.repository.index();
  }
}