import { OnDemandRepository } from '../../../domain/OnDemandRepository'

export class AllShowFileNamesGetter {
    
 private repository: OnDemandRepository;

  constructor(repository: OnDemandRepository) {
    this.repository = repository;
  }

  async run(showSlug: String): Promise<any[]> {
    return await this.repository.getShowNames(showSlug);
  }
}