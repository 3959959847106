import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "OpenSans-Bold",
    fontSize: "3.5em",
  },
  schedule: {
    fontFamily: "OpenSans-Light",
    fontSize: "1.5rem !important",
    paddingLeft: "0.5em",
    textTransform: "uppercase",
  },
  moreButton: {
    outline: "none !important",
  },
  noDisplay: {
    fontSize: 50,
    display: "none",
  },
  display: {
    fontSize: 50,
    display: "flex",
  },
}));

const MiniSiteShowSchedule = (props) => {
  let monToFriday = isMondayToFriday(props.schedule) ? true : false;
  let scheduleData = chunkSchedule(props.schedule) || [];

  if (monToFriday === false)
    return (
      <Grid container spacing={3} justifyContent="flex-start">
        {Array.isArray(scheduleData) && scheduleData.map((scheduleDataRow) => {
            return scheduleDataRow !== undefined && (
              <Grid item key={scheduleDataRow.id}>
                <ScheduleRow schedule={scheduleDataRow}></ScheduleRow>
              </Grid>
            );
        })}
      </Grid>
    );
  if (monToFriday === true) {
    let newScheduleDataRow = {
      weekDay: "DE LUNES A VIERNES",
      startTime: scheduleData[0].startTime,
    };
    return (
      <Grid container spacing={3} justifyContent="flex-start">
        <Grid item>
          <ScheduleRow key={"key"} schedule={newScheduleDataRow}></ScheduleRow>
        </Grid>
      </Grid>
    );
  }
};

const ScheduleRow = (props) => {
  const classes = useStyles();
  const time = moment(props.schedule.startTime, "HH:mm:ss").format("h:mm a");

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
    >
      <Grid item>
        <Typography className={classes.schedule}>
          {props.schedule.weekDay}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.schedule}>{time}</Typography>
      </Grid>
    </Grid>
  );
};

function chunkSchedule(schedule = []) {
  let cont = 0;
  let weekDay,
    weekDayAux = "";
  let days = [];
  let schedulesToShow = [];

  schedule.forEach((s) => {
    if (days.includes(s.weekDay)) {
      cont++;
    } else {
      schedulesToShow.push(s);
    }
    days.push(s.weekDay);
  });
  return schedulesToShow;
}

function isMondayToFriday(schedule = []) {
  let days = [];

  schedule && schedule?.forEach((s) => days.push(s.weekDay));
  if (
    days.includes("lunes") &&
    days.includes("martes") &&
    days.includes("miercoles") &&
    days.includes("jueves") &&
    days.includes("viernes")
  )
    return true;
  else return false;
}

export default MiniSiteShowSchedule;
