import { ShowRepository } from "../../../core/shows/domain/ShowRepository";
import { AxiosShowRepository } from "../../../core/shows/infrastructure/repository/AxiosShowRepository";
import { ShowImageGetter } from "../../../core/shows/application/useCases/getShowImage/showImageGetter";
import { ShowImage } from "../../../core/shows/domain/ShowImage";

const showRepository: ShowRepository = new AxiosShowRepository();
const showImageGetter = new ShowImageGetter(showRepository);
let image: ShowImage;

export const fetchShowImage = async (imageName: string, showSlug: string) => {
  try {
    const data = await showImageGetter
      .run(imageName, showSlug);
    image = data;
    return image;
  } catch (err) {
    console.log(err);
    return null;
  }
};
