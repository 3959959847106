import { UserRepository } from "../../../domain/UserRepository";

export class DetailUserGetterById {
  private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(_id: string): Promise<any> {
    return await this.repository.getUserById(_id);
  }
}
