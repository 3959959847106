import ShowMiniSite from '../components/miniSites/showMiniSiteComponent';
import { fetchShowBySlug } from '../redux/actions/shows/fetchShowBySlug';
import { useParams } from "react-router-dom";
import { getShowReducer } from 'redux/reducers/shows/getShowReducer';
import { useReducer, useEffect } from "react";
import TNOBusinessMiniSite from 'components/miniSites/miniSiteTNOBusiness';

const ShowSite = () => {
  const { showSlug } = useParams<{ showSlug: string }>();
  const [show, dispatch] = useReducer(getShowReducer, null);

  useEffect(() => {
    showSlug !== "tnobusiness" && getShowBySlug(showSlug);
  }, [showSlug]);
  
  const getShowBySlug = async (showSlug: string) => {
    const response = await fetchShowBySlug(showSlug);
    dispatch({ type: "FETCH_SHOW_BY_SLUG", showBySlug: response });
  };

  return (
    <>
      {showSlug === "tnobusiness" ? <TNOBusinessMiniSite /> : <ShowMiniSite showBySlug={show} /> }
    </>
  );
};

export default ShowSite;
