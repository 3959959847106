import * as types from "../../types/types";

let initialState =  [];

export const fetchHostsReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_HOSTS:
      return action.hostsList;

    default:
      return state;
  }
};
