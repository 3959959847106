import React from 'react';
import ScheduleNavbar from './scheduleNavbar';

export default function ScheduleLayout({ children }) {
  return (
    <div>
      <ScheduleNavbar></ScheduleNavbar>
      <div style={{minHeight:"30vh"}}>
        {children}   
      </div> 
    </div>       
  )     
}