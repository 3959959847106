import { ShowRepository } from "../../../domain/ShowRepository";

export class ShowAdvertisersGetter {
  private repository: ShowRepository;

  constructor(repository: ShowRepository) {
    this.repository = repository;
  }

  async run(showId): Promise<any> {
    return await this.repository.getShowAdvertisers(showId);
  }
}
