import * as types from "../../types/types";

let initialState = {};

export const PostsBySlugReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_POST_BY_SLUG:
      return action.postBySlug ;
    default:
      return state;
  }
};
