import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { useMemo } from "react";

interface StyleProps {
    tagSize: string;
    isHome: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    tags: {
        paddingLeft: ({ isHome }) => isHome === true ? "1rem" : "3rem",
        paddingTop: "2rem",
        '& span': {
          fontSize: ({tagSize}) => tagSize,
        },
        '& p': {
            fontSize: ({tagSize}) => tagSize,
        },
        "@media(max-width: 600px)": {
          paddingLeft: "5%",
          paddingRight: "5%",
        },
      },  
  })
);
const PostTags = ({ tags, tagSize, isHome}) => {
    const classes = useStyles({tagSize, isHome});
    const colors = ["primary", "success", "danger", "info", "deep-purple", "pink", "lime"];
   
    const renderTags = useMemo(() => (
        <p className={classes.tags}>
          {tags?.map((tag: string) => (
            <span
              key={tag}
              className={`${colors[Math.floor(Math.random()*colors.length)]} mr-1`}
            >
              {tag}
            </span>
          ))}
      </p>
      ), [tags]);
      
    return (
        <>
            {renderTags}
        </>
    );
};

export default PostTags;