import { AxiosShowRepository } from "../../../core/shows/infrastructure/repository/AxiosShowRepository";
import { ShowRepository } from "../../../core/shows/domain/ShowRepository";
import { ShowAdvertisersGetter } from "../../../core/shows/application/useCases/getShowAdvertisers/showAdvertisersGetter";

const showRepository: ShowRepository = new AxiosShowRepository();
const showAdvertisersGetter: ShowAdvertisersGetter = new ShowAdvertisersGetter(
  showRepository
);
let showAdvertisers = [];

export const fetchShowAdvertisers = async (showId: string) => {
  try {
    showAdvertisers = await showAdvertisersGetter.run(showId);
    return showAdvertisers;
  } catch (err) {
    console.log("fetchShowAdvertisers ", err);
    return [];
  }
};
