import { useEffect, useState } from "react";
import { ShowSchedule } from "../../core/shows/domain/ShowSchedule";
import utils from "../../core/shows/domain/utils";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ScheduleRow from "./scheduleRow";

const createScheduleData = (weekDay: string, startTime: string) => {
  return { weekDay, startTime };
};

/**
 * Component, renders other schedule of a show.
 */
const OtherSchedules = (props) => {
  const [otherSchedules, setOtherSchedules] = useState(props.otherSchedules);

  useEffect(() => {
    getOtherSchedules(otherSchedules);
  }, [props]);

  async function getOtherSchedules(schedules: ShowSchedule[]) {
    let scheduleRows = schedules?.map((schedule) => {
      if (
        schedule !== undefined && schedule !== null &&
        schedule.weekDay.localeCompare(props.weekday) !== 0
      ) {
        return createScheduleData(
          schedule.weekDay,
          utils.getTimeFromDate(schedule.startTime)
        );
      } else return null;
    });

    if (scheduleRows !== undefined && scheduleRows !== null)
      setOtherSchedules(scheduleRows);
    else setOtherSchedules([]);
  }

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableBody>
          {otherSchedules.map((row) => {
            if (row !== undefined)
              return (
                <ScheduleRow key={row?.weekDay + row?.startTime} row={row} />
              );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OtherSchedules;
