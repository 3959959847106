/**
 * Implements User repository interface with axios
 * promise based HTTP client.
 */

import { User } from "../../domain/User";
import { UserRepository } from "../../domain/UserRepository";
//import useSWR from 'swr'
import axios from "axios";

const baseURL = 'https://users.tnoradio.com/api/users/';
//const baseURL = 'http://localhost:5000/api/users/';

export class AxiosUserRepository implements UserRepository {
  public async save(user: User): Promise<User> {
    try {
      const response = await axios.post(baseURL + "save", user);
      /**TODO: hacer un User.create con la respuesta para agregarle el id */
      //   User.create();
      return response.data.user_data;
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      return user;
    }
  }

  public async isValidEmail(email: string): Promise<boolean> {
    try {
      const response = await axios({
        url: baseURL + "verifyemail",
        method: "get",
        params: { email: email.toString() },
      });

      if (response.data.isTaken === "Email taken") {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      return false;
    }
  }

  public async getUserById(_id: string): Promise<User> {
    try {
      const response = await axios.get(`${baseURL}getuserbyid/${_id}`);
      return response.data;
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getUserBySlug(slug: string): Promise<User> {
    try {
      const response = await axios.get(baseURL + "details/" + slug);
      return response.data;
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getHosts(): Promise<User[]> {
    try {
      const response = await axios.get(baseURL + "index");
      const hosts = response.data.filter((user) =>
        user.roles.find((role) => role.role === "HOST")
      );
      return hosts;
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getTeam(): Promise<User[]> {
    try {
      const response = await axios.get(baseURL + "index");
      const team = response.data.filter((user) =>
        user.roles.find(
          (role) =>
            role.role === "EMPLOYEE" ||
            role.role === "ADMIM" ||
            role.role === "MASTER"
        )
      );
      return team;
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getUserImage(name, slug) {
    let res;
    try {
      res = await axios.get(baseURL + "imagefromdb/" + name + "/" + slug);

      return res.data;
    } catch (error) {
      console.log("AxiosUserRepository ", error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
}
