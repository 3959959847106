import { ShowRepository } from '../../../core/shows/domain/ShowRepository';
import { AxiosShowRepository } from '../../../core/shows/infrastructure/repository/AxiosShowRepository';
import { AllShowsGetter } from 'core/shows/application/useCases/getShows/allshowsgetter';

const showRepository: ShowRepository = new AxiosShowRepository();
const indexShows = new AllShowsGetter(showRepository);

export const fetchUserShows = async (hostId: String) =>  {
    try {
        const shows = await indexShows.run();
        const userShows = shows.filter(show => show.hosts.some(host => host.userId === hostId));
        return userShows;
    } catch (err) {
        console.log(err);
        return [];
    }
}
  

 
  
