import { useParams } from "react-router-dom";
import { useReducer, useEffect } from "react";
import { fetchUserReducer } from 'redux/reducers/users/fetchUserReducer';
import UserMiniSiteComponent from "components/miniSites/users/userMiniSiteComponent";
import { fetchUserBySlug } from "redux/actions/users/fetchUserBySlug";

const UserSite =  () => {
  const { userSlug } = useParams<{ userSlug: string }>();
  const [user, dispatch] = useReducer(fetchUserReducer, null);

  useEffect(() => {
    getUser(userSlug);
  }, [userSlug]);

  const getUser = async (userSlug) => {
    const response = await fetchUserBySlug(userSlug);
    dispatch({ type: "FETCH_USER_BY_SLUG", userBySlug: response });
  };

  return (<UserMiniSiteComponent userBySlug={user} />);
}

export default UserSite;
