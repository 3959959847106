import { ShowRepository } from "../../../core/shows/domain/ShowRepository";
import { AxiosShowRepository } from "../../../core/shows/infrastructure/repository/AxiosShowRepository";
import { ShowsOnWeekdayGetter } from "../../../core/shows/application/useCases/getShowsOnWeekday/showsonweekdaygetter";
import * as types from "../../types/types";
import { ShowSchedule } from "../../../core/shows/domain/ShowSchedule";
import { Show } from "../../../core/shows/domain/Show";

const showRepository: ShowRepository = new AxiosShowRepository();
const showsOnWeekday = new ShowsOnWeekdayGetter(showRepository);
let shows: Show[] = [];
let daySchedule: Show[] = [];
let weekDayParam: string;

export const reduxFetchShowsOnDay = (weekday) => (dispatch) => {
  dispatch({
    type: types.CHANGE_LOAD_SCHEDULE_STATE,
    loadScheduleState: "loading",
  });
  weekDayParam = weekday;
  if (weekday === "miércoles") weekDayParam = "miercoles";
  if (weekday === "sábado" || weekday === "sabado") weekDayParam = "sabado";
  showsOnWeekday
    .run(weekDayParam)
    .then((data) => {
      shows = data;
      sortShowsListByStartTime(shows);
      dispatch({
        type: types.CHANGE_LOAD_SCHEDULE_STATE,
        loadScheduleState: "loaded",
      });
      dispatch({
        type: types.SHOWS_FILTERED_BY_DAY,
        showsOnWeekdayList: daySchedule,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: types.SHOWS_FILTERED_BY_DAY, showsOnWeekdayList: [] });
      dispatch({
        type: types.CHANGE_LOAD_SCHEDULE_STATE,
        loadScheduleState: "notLoaded",
      });
    });
};

export const fetchShowsOnDay = async (weekday: string) => {
  weekDayParam = weekday;
  weekDayParam = weekday === "miércoles" ? "miercoles" : weekday;
  weekDayParam = weekday === "sábado" ? "sabado" : weekday;
  try {
    const data = await showsOnWeekday.run(weekDayParam);
    shows = filterShowsByDay(data, weekDayParam);
    sortShowsListByStartTime(shows);
    return shows;
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * This will sort shows by init time once data is stored correctly
 * in database.
 * @param shows
 */
function sortShowsListByStartTime(shows: Show[]) {
  shows.forEach((show) => {
    sortSchedule(show.showSchedules);
  });
}

function sortSchedule(schedules: ShowSchedule[]) {
  schedules.sort(function (a, b) {
    var keyA = a.startTime;
    var keyB = b.startTime;
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
}

const filterShowsByDay = (shows: Show[], day: string) => {
  return shows.filter((show) => 
    show.showSchedules.some((showSchedule) => 
      showSchedule.weekDay.localeCompare(day) === 0
     )
  );
}
