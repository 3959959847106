import React, {useEffect, useState} from "react";

declare const window: any;
const useCheckMobileScreen = () => {

    const [width, setWidth] = useState(window.innerWidth || 0);
    
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 560);
}

export default useCheckMobileScreen;