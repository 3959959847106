import React from "react";

const LiveNow = () => {

  const classes = {
    image: {
     marginTop: "0px",
     cursor: "pointer",
    },
    img: {
      width: "100%",
     }, 
  };

  return (
    <div style={classes.image}>
       <img
          style={classes.img}
          src="miniSiteBanner_tnoradio_prop.webp"
          alt="Radio visual Venezuela"
          onClick={() => window.open("/tnobusiness")}
        />
      </div>
  );
};

export default LiveNow;