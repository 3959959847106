import Layout from "../layout";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import MiniSiteShowBanner from "./miniSiteShowBanner";
import MiniSiteSynopsis from "./showSynopsis";
import MiniSiteSocial from "./miniSiteSocial";
import MiniSiteShowSchedule from "./minisiteShowSchedule";
import MiniSiteShowAdsGallery from "./miniSiteShowAdGallery";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import TalentImage from "./miniSiteHosttImage";
import CircularProgress from "@mui/material/CircularProgress";
import MiniSiteShowOnDemand from "./miniSiteShowOnDemand";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getUserById } from "../../redux/actions/users/fetchUserById";
import Link from "@mui/material/Link";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';
import { constants } from "buffer";

const useStyles = makeStyles(() => ({
  root: {
    background: "linear-gradient(90deg, rgba(65,1,61,1),  rgba(74,46,72,1))",
  },
  progress: {
    height: "50vh",
  },
  onDemand: {
    padding: "20px",
    background: "url(/bkgs/bckg-home-footer.jpg) no-repeat;",
    backgroundPosition: "right",
  },
  bigGallery: {
    width: "100%", 
    '& ul': {
      height:"100%",
    },
  },
  showBanner: {
    backgroundColor: "#cd7f9e",
  },
  scheduleContainer: {
    minHeight:"100px",
    background: "rgba(11,135,147) url('/block-time.jpg')", //linear-gradient(to bottom, )) no-repeat",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    overflow: "hidden",
    backgroundPosition: "bottom",
  },
  titleContainer: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
    color: "white",
  },
  synopsis: {
    background: "linear-gradient(90deg, rgba(65,1,61,1),  rgba(74,46,72,1))",
    paddingLeft: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  talentImage: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  adsContainer: {
    background: "rgba(11, 135, 147, 1)",
    paddingLeft: "5px",
  },
}));


const ShowMiniSite = ({ showBySlug }) => {
  const classes = useStyles();
  const theme = createTheme();
  const [hosts, setHosts] = useState([]);

  const getHostById = async (id) => {
    return await getUserById(id);
  };

  useEffect(() => {
      showBySlug !== null && showBySlug !== "not Found" && showBySlug?.hosts?.map(async (host) => {
        const hostData = await getHostById(host.userId);
        setHosts(hosts => [...hosts, hosts.some(host => host === hostData) === false ? hostData : []]);
      });
  }, [showBySlug]);

  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 1
    }
  };

  theme.typography.h3 = {
    fontSize: '3rem',
    fontFamily: "OpenSans-Bold",
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
  };

  const getTwitterUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "Twitter"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://www.twitter.com/tnoradio";
  };
  
  const getTikTokUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "TikTok"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://www.tiktok.com/@tnoradio";
  };

  const getFacebookUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "Facebook"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://www.facebook.com/tnoradio";
  };

  const getInstagramUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "Instagram"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://www.instagram.com/tnoradio";
  };

  const getSnapchatUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "Snapchat"
    );
    if (social !== undefined) return social.url;
    else return "";
  };

  const getYoutubeUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "Youtube"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://youtube.com/c/tnoradio";
  };

  const getTuneInUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "TuneIn"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://tunein.com/radio/TNO-RADIO-s236350";
  };

  const getLinkedinUrl = () => {
    var social = showBySlug?.socials?.find(
      (social) => social.name === "Linkedin"
    );
    if (social !== undefined) return social.url + social.userName;
    else return "https://ve.linkedin.com/company/tno-radio";
  };

  if (showBySlug !== null && showBySlug !== "not Found") {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          {/*<MiniSiteShowBanner showSlug={showBySlug.showSlug} />*/}
          <Grid container className={classes.root}>
            <Grid className={classes.talentImage} item xs={12} md={4} xl={3}>
              <Carousel responsive={responsive} className={classes.bigGallery} autoPlay={false}>
                {hosts.map((host) => {
                  return (
                    <Tooltip title={`Haz click en la foto para conocer a ${host.name} ${host.lastName}`}>
                    <Link href={`/talento/${host.slug}`} underline="none">
                      <TalentImage
                        key={host.id}
                        talentSlug={host.slug}
                        type="talent"
                      />
                    </Link>
                    </Tooltip>
                  )})}
              </Carousel>
            </Grid>
            <Grid item xs={12} md={8} xl={9}>
              <Grid className={classes.scheduleContainer}>
                <Grid item className={classes.titleContainer} xs={12}>
                  <MiniSiteShowSchedule schedule={showBySlug.showSchedules} />
                </Grid>
              </Grid>
              <Grid className={`${classes.titleContainer} ${classes.synopsis}`}>
                <Typography variant="h3">
                  SINOPSIS 
                </Typography>
                <MiniSiteSynopsis synopsis={showBySlug.synopsis} />
              </Grid>
              <Grid className={classes.adsContainer}>
                <MiniSiteShowAdsGallery showId={showBySlug.id} />
              </Grid>
              <Grid container className={classes.socials}>
                <MiniSiteSocial
                  twitter={getTwitterUrl()}
                  facebook={getFacebookUrl()}
                  instagram={getInstagramUrl()}
                  linkedin={getLinkedinUrl()}
                  snapchat={getSnapchatUrl()}
                  tuneIn={getTuneInUrl()}
                  youtube={getYoutubeUrl()}
                  tikTok={getTikTokUrl()}
                  />               
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.onDemand} container>
              <Typography fontFamily={"OpenSans-Bold"} color="whitesmoke" fontSize={"2rem"}>
                {showBySlug.name} a la carta
              </Typography>
              <MiniSiteShowOnDemand showSlug={showBySlug.showSlug} />
            </Grid>
          </Grid>
        </Layout>
      </ThemeProvider>
    );
  }
  if (showBySlug === null) {
    return (
      <Layout>
        <Grid
          className={classes.progress}
          container
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Grid>
      </Layout>
    );
  } else if (showBySlug === "not Found")
    return (
      <div>
        <Layout>
          <Grid
            className={classes.progress}
            container
            alignItems="center"
            justifyContent="center"
          >
            <h1>{"El programa que buscas no existe o está mal escrito en la url."}</h1>
          </Grid>
        </Layout>
      </div>
    );
};

export default (ShowMiniSite);
