import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import utils from "../../core/shows/domain/utils";
import OtherSchedules from "./otherSchedules";
import { Link } from "react-router-dom";
import MiniBannerComponent from "./miniBannerComponent";

const useRowStyles = makeStyles(() => ({
  otherRowHeader: {
    "& > *": {
      padding: "0px",
    },
  },
  root: {
    "& > *": {
      padding: "0px",
     // background: open ? "rgba(48, 65, 140, 1) " : "white ",
    //  color: open ? "white !important" : "rgba(48, 65, 140, 1) !important",
    },
    "& tr": {
    },
  },
  button: {
    paddingLeft: "0.5em",
    paddingRight: "2em",
  },
  miniBanner: {
    background: "rgba(48, 65, 140, 1)",
    padding: "0 !important",
    "&:hover": { cursor: "pointer " },
  },
  showTitleCell: {
    fontFamily: "OpenSans-Bold",
    paddingLeft: ".3em",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
      background: "rgba(48, 65, 140, 1)",
      color: "white !important",
    },
  },
  showTitle: {
    fontSize: "2rem !important",
    verticalAlign: "middle !important",
    fontFamily: "OpenSans-Bold !important",
    padding: "1em",
    color: "rgba(48, 65, 140, 1)",
    "&:hover": {
      color: "white",
    },
    "@media (max-width:599px)": {
      fontSize: "1rem !important",
      padding: "0em !important",
    },
  },
  genreCell: {},
  genre: {
    fontFamily: "OpenSans-Light !important",
    paddingLeft: "2em",
    textTransform: "uppercase",
  },
  hosts: {
    fontSize: "0.6m",
  },
  timeText: {
    fontFamily: "OpenSans-Light !important",
    color: "white !important",
    fontSize: "1.5rem !important",
    "@media (max-width:599px)": {
      fontSize: "1em !important",
    },
  },
  time: {
    paddingLeft: "1em",
    paddingRight: "1em",
    backgroundColor: "rgba(48, 65, 140, 1)",
    "@media (max-width:599px)": {
      paddingLeft: "0.5em !important",
      paddingRight: "0.5em !important",
    },
  },
  synopsisCell: {
    fontFamily: "OpenSans-Bold",
    verticalAlign: "middle",
  },
  synopsis: {
    //paddingRight:"1em",
    fontFamily: "OpenSans-Light !important",
    fontSize: "1.2em",
    verticalAlign: "middle",
    textAlign: "justify",
  },
  openHeader: {
    //paddingLeft:"1em",
    fontFamily: "OpenSans-Bold!important",
    textTransform: "uppercase",
    paddingRight: "1em",
    fontSize: "1.5em",
    color: "rgba(48, 65, 140, 1)",
  },
  otherSchedulesHeader: {
    //paddingLeft:"1em",
    fontFamily: "OpenSans-Bold !important",
    textTransform: "uppercase",
    textAlign: "center",
    paddingRight: "1em",
    fontSize: "1.5em",
    color: "rgba(48, 65, 140, 1)",
  },
  classHeader: {
    //paddingLeft:"1em",
    //paddingRight:"1em",
    // fontSize: "1.5em",
  },
  classification: {
    fontFamily: "OpenSans-Light !important",
    verticalAlign: "middle",
    fontSize: "1em",
  },
}));

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const weekDayParam: string = props.weekday;

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <TableCell className={classes.time} align="center" sx={{width: {xs:"5%", md: "10%"} }}>
          <Typography className={classes.timeText}>
            &nbsp;
            {utils.formatTime(row.startTime)}
            &nbsp;
          </Typography>
        </TableCell>
        {/*<TableCell width="20%" className={classes.miniBanner} sx={{ display: { sm: 'table-cell', xs: 'none' } }}>
          <MiniBannerComponent showSlug={row.showSlug} />
      </TableCell>*/}
        <TableCell width="65%" className={classes.showTitleCell} align="left">
          <Typography
            className={classes.showTitle}
            display="block"
            component="div"
          >
            <Link to={`/${row.showSlug}`}>
              {row.name}
            </Link>
          </Typography>
        </TableCell>
        <TableCell width="5%" size="small" align="center" sx={{ display: { sm: 'table-cell', xs: 'none' } }}>
          <Typography className={classes.genre}>{row.genre}</Typography>
        </TableCell>
        <TableCell width="5%" className={classes.button} sx={{ display: { sm: 'table-cell', xs: 'none' } }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={6}
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit orientation="vertical">
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead className={classes.otherRowHeader}>
                  <TableRow className={classes.otherRowHeader}>
                    <TableCell width="70%" className={classes.openHeader}>
                      Sinopsis
                    </TableCell>
                    <TableCell
                      width="10%"
                      className={classes.openHeader}
                      size="small"
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      width="20%"
                      className={classes.otherSchedulesHeader}
                    >
                      {"Otros\n Horarios"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      width="70%"
                      align="left"
                      className={classes.synopsisCell}
                    >
                      <Typography className={classes.synopsis} align="left">
                        {row.synopsis}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width="10%"
                      align="left"
                      className={classes.classification}
                      size="small"
                    >
                      {row.pgClassification}
                    </TableCell>
                    <TableCell width="20%" align="left">
                      <OtherSchedules
                        otherSchedules={row.showSchedule}
                        weekday={weekDayParam}
                      ></OtherSchedules>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
