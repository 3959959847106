import { PostRepository } from '../../../domain/PostRepository'

export class DetailPostGetter {
    
 private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(slug: string): Promise<any> {
    return await this.repository.getPostBySlug(slug);
  }
}