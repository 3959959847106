import {useEffect, useState} from "react";

declare const navigator: any;

export const useCheckNetworkSpeed = () => {
    const [speed, setSpeed] = useState(0);
    
    const handleNetworkSpeedChange = () => {
        console.log("Network speed changed ", navigator.connection.downlink);
        setSpeed(navigator.connection.downlink);
    }

    useEffect(() => {
        setSpeed(navigator?.connection?.downlink);
        navigator?.connection?.addEventListener("change", handleNetworkSpeedChange);
    }, []);

    return speed;
}