/**
 * Pretty Formats Init Time
 * @param schedules 
 * @param weekday 
 */
const formatTime = (startTime: Date):string => {
    var startTimeRes='';
    try{
          var pieces = startTime.toString().split(':');
          var hour;
          var minute; 
          var second;
  
        if(pieces.length === 3) {
            hour = parseInt(pieces[0], 10);
            minute = parseInt(pieces[1], 10);
            second = parseInt(pieces[2], 10);
        }
  
          startTimeRes = pieces[0] + ":" + pieces[1];
        
      return startTimeRes;
    }catch(e){
      return "";
    }
  }

export default formatTime;
  