import React, { useReducer, useEffect } from "react";
import Grid from "@mui/material/Grid";
import fetchUserImage from "../redux/actions/users/fetchUserImage";
import { Card, CardTitle } from "reactstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from "react-router-dom";
import Layout from "./layout";
import { fetchUserImageReducer } from "redux/reducers/users/fetchUserImageReducer";
import { Buffer } from "buffer";

const UserCard = ({ isTeamPlayer, user }) => {
  const [data, setData] = React.useState("");
  const [ userImage, dispatch ] = useReducer(fetchUserImageReducer, []);

  useEffect(() => {
    const getUserImage = async () => {
      const imageData = await fetchUserImage("profile", user.slug);

      if (imageData !== undefined && imageData !== null) {
        let data = Buffer.from(
          imageData.file.data,
          "binary"
        ).toString("base64");
        setData(data);
        dispatch({type: "FETCH_USER_IMAGE", userImage: data});
      }
    };

    getUserImage();
  }, [data]);

  if (user !== undefined)
    return (
      <Card className="text-left">
        <div className="card-img">
          {data !== null && data !== "" && data !== undefined ? (
            <img width="100%" src={`data:image/jpeg;base64,${data}`} alt="Talento TNO Radio"/>
          ) : (
            <img width="100%" src="talent_proportion_tnoradio.jpg" alt="Talento TNO Radio"/>
          )}
          <CardTitle>{`${user.name}   ${user.lastName}`}</CardTitle>
          <Link
            to={
              isTeamPlayer === true
                ? `/equipo/${user.slug}`
                : `/talento/${user.slug}`
            }
            className="btn btn-floating halfway-fab"
            target="_blank"
            underline="none"
          >
            <AddCircleOutlineIcon fontSize="medium" color="#FFF" />
          </Link>
        </div>
      </Card>
    );
  else
    return (
      <div>
        <Layout>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
            <h1>{"Ha ocurrido un problema."}</h1>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
            <Link to={`/talento/${user.slug}`} underline="none">
              <h4>{"volver a inicio"}</h4>
            </Link>
          </Grid>
        </Layout>
      </div>
    );
};

export default UserCard;
