import { PostRepository } from "../../../domain/PostRepository";
import Post from "../../../domain/Post";

export class PostsPageByOwnerGetter {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(pageSize: number, page: number, owner: String): Promise<Post[]> {
    return await this.repository.getPostsPageByOwner(pageSize, page, owner);
  }
}
