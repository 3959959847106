import { UserRepository } from '../../../domain/UserRepository'
import { User } from '../../../domain/User'

export class HostsGetter {
    
 private repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  async run(): Promise<User[]> {
    return await this.repository.getHosts();
  }
}