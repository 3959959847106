import { ShowRepository } from '../../../domain/ShowRepository'
import { Show } from '../../../domain/Show'

export class ShowsOnWeekdayGetter {
    
 private repository: ShowRepository;

  constructor(repository: ShowRepository) {
    this.repository = repository;
  }

  async run(weekday: string): Promise<Show[] | []> {
    return await this.repository.getShowsOnWeekday(weekday);
  }
}