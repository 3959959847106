import { ShowHost } from '../core/shows/domain/ShowHost';
import { ShowSchedule } from '../core/shows/domain/ShowSchedule'

export class IShowScheduleRow {
    id: Number;
    name: string;
    genre: string;
    type: string;
    synopsis: string;
    pgClassification: string;
    hosts: ShowHost[];
    startTime: Date;
    showSchedule: ShowSchedule[];
    showSlug: String;    
    
    constructor(
        id: Number, name: string, genre: string, type: string, synopsis: string, pgClassification: string,
        hosts: ShowHost[], startTime: Date, showSchedule: ShowSchedule[],
        showSlug: String) {
            this.id = id;
            this.name = name;
            this.genre = genre;
            this.synopsis = synopsis;
            this.type = type;
            this.pgClassification = pgClassification;
            this.hosts = hosts;
            this.startTime = startTime;
            this.showSchedule = showSchedule;
            this.showSlug = showSlug;

            return {
                id,
                name,
                genre,
                type,
                synopsis,
                pgClassification,
                hosts,
                startTime,
                showSchedule,
                showSlug
            }
        }
    }