import { Show } from "core/shows/domain/Show";
import * as types from "../../types/types";

let initialState = null;

export const getShowReducer = (state = initialState, action): Show => {
    switch (action.type) {
        case types.FETCH_SHOW_BY_SLUG:
            return action.showBySlug;
        default:
            return state;
    }
};