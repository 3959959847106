import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import ShowSite from 'pages/show';
import UserSite from 'pages/user';
import ShowsSchedule from 'pages/schedules';
import Hosts from 'pages/talentos';
import Post from 'pages/post';
import PostsList from 'pages/posts';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/:showSlug",
    element: <ShowSite />,
  },
    {
    path: "/:showSlug",
    element: <ShowSite />,
  },
  { path: "/talento/:userSlug",
    element: <UserSite />,
  },
  { path: "/equipo/:userSlug",
    element: <UserSite />,
  },
  { path: "/programacion",
    element: <Navigate to="/programacion/lunes" />,
  },
  { path: "/programacion/:weekDay",
    element: <ShowsSchedule />,
  },
  {path: "/talentos",
    element: <Hosts />,
  },
  { path: "/contenido/:slug",
    element: <Post />,
  },
  { path: "/contenido/posts",
    element: <PostsList />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
