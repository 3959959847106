import Image from "react-bootstrap/Image";
import React, {useReducer} from "react";
import fetchUserImage from "../../redux/actions/users/fetchUserImage";
import { fetchUserImageReducer } from "redux/reducers/users/fetchUserImageReducer";
import { Buffer } from 'buffer';

const TalentImage = ({ talentSlug, type}) => {
  const [userImageData, dispatch] = useReducer(fetchUserImageReducer, null);

  const getUserImage = async (type, talentSlug) => {
    const response = await fetchUserImage(type, talentSlug);
    const data = response && Buffer.from(
        response.file.data,
        "binary"
      ).toString("base64");

    dispatch({ type: "FETCH_USER_IMAGE", userImage: data });
  };

  React.useEffect(() => {
    getUserImage(type, talentSlug);
  }, [talentSlug]);

  if (userImageData !== null && userImageData !== "") {
    return (
      <Image
        style={{ height: "100%" }}
        src={userImageData && `data:image/jpeg;base64,${userImageData}`}
        fluid
      />
    );
  } else
    return (
      <Image
        style={{ height: "100%" }}
        src="https://randomuser.me/api/portraits/lego/1.jpg"
        fluid
      />
    );
};

export default TalentImage;
